import React, { useEffect, useState } from 'react';
import Header from './Header';
import LineGrid from './LineGrid';

import { getHosts } from '../../services/Host/request';
import { RequestHost } from '../../services/Host/types';
import HeaderPage from './HeaderPage';
import ModalMoreInformation from './ModalMoreInformation';
import { Container, Content, Wrapper } from './styles';

const ViewHostsComponent: React.FC = () => {
  const [listHost, setListHost] = useState<Array<RequestHost>>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [hostSelected, setHostSelected] = useState<RequestHost>({} as RequestHost);
  const [typeSort, setTypeSort] = useState('a-z');

  useEffect(() => {
    async function getListHost() {
      const result = await getHosts();
      const filteredResult = result.filter((item) => item.is_host_active === true);
      setListHost(filteredResult);
    }
    getListHost();
  }, []);

  function handleSort() {
    if (listHost && typeSort === 'a-z') {
      const strAscending = [...listHost].sort((a, b) => (a.name > b.name ? 1 : -1));
      setListHost(strAscending);
      setTypeSort('z-a');
    } else if (listHost) {
      const strAscending = [...listHost].sort((a, b) => (a.name < b.name ? 1 : -1));
      setListHost(strAscending);
      setTypeSort('a-z');
    }
  }
  return (
    <Wrapper>
      <HeaderPage />
      <Container>
        <Header onClick={handleSort} />
        <Content>
          {listHost?.map((item) => (
            <LineGrid host={item} setOpenModal={setOpenModal} setHostSelected={setHostSelected} />
          ))}
        </Content>
        <ModalMoreInformation host={hostSelected} onClose={setOpenModal} open={openModal} />
      </Container>
    </Wrapper>
  );
};

export default ViewHostsComponent;
