import { Modal } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useToast } from '../../../../context/ToastContext';
import { deleteOwnerContact } from '../../../../services/Owner/request';
import { useToastErrorMessage } from '../../../../utils/Messages';

import {
  Wrapper,
  Container,
  ButtonCancel,
  ButtonClose,
  ButtonSave,
  HeaderModalNewBankAccount,
  ButtonsContainer,
} from './styles';

interface Props {
  openModal: boolean;
  idContact: number | string;
  setOpenModal: (a: boolean) => void;
  updateListContact: () => Promise<void>;
  sliderRef?: any,
}

const ModalConfirmDeleteContact = ({
  openModal,
  setOpenModal,
  idContact,
  updateListContact,
  sliderRef,
}: Props) => {
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  async function handleDeleteContact(id: number | string) {
    const contactId = `${id}`;

    if (contactId.startsWith('new_')) {
      await updateListContact();
      toast.success('Contato excluído com sucesso!');
      handleCloseModal();
      if (sliderRef?.current) {
        sliderRef?.current?.slickGoTo(0);
      }
      return;
    }

    try {
      await deleteOwnerContact(Number(id));
      await updateListContact();
      toast.success('Contato excluído com sucesso!');
      handleCloseModal();
      if (sliderRef?.current) {
        sliderRef?.current?.slickGoTo(0);
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        toastErrorRequest(e);
      }
    }
  }

  if (!openModal) return null;

  return (
    <Modal open={openModal}>
      <Wrapper>
        <Container>
          <HeaderModalNewBankAccount>
            <h4>Excluir contato?</h4>
            <ButtonClose onClick={handleCloseModal}>
              <Close />
              Fechar
            </ButtonClose>
          </HeaderModalNewBankAccount>
          <ButtonsContainer>
            <ButtonCancel onClick={handleCloseModal} type="button">
              Cancelar
            </ButtonCancel>
            <ButtonSave type="button" onClick={() => handleDeleteContact(idContact)}>
              Excluir
            </ButtonSave>
          </ButtonsContainer>
        </Container>
      </Wrapper>
    </Modal>
  );
};

ModalConfirmDeleteContact.defaultProps = {
  sliderRef: undefined,
};

export default ModalConfirmDeleteContact;
