import {
  ReportBugModalBackdrop,
  ReportBugModalButtonsContainer,
  ReportBugModalCard,
  ReportBugModalCloseButton,
  ReportBugModalCloseIcon,
  ReportBugModalContentContainer,
  ReportBugModalHeader,
  ReportBugModalImageContainer,
  ReportBugModalText,
  ReportBugModalTextContainer,
  ReportBugModalTitle,
} from './styles';
import BugImage from '../../assets/icons/generals/bugImage.png';
import FormButton from '../FormButton';

interface ReportBugModalProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  url?: string;
}

const ReportBugModal = ({
  isModalOpen,
  handleCloseModal,
  url = 'https://suportesapron.atlassian.net/servicedesk/customer/portal/3/group/14/create/60',
}: ReportBugModalProps) => {
  function handleRedirectToBugReport() {
    window.open(url, '_blank');
    handleCloseModal();
  }

  return (
    <ReportBugModalBackdrop
      isModalOpen={isModalOpen}
      onClick={handleCloseModal}
      data-testid="report-bug-modal-backdrop"
    >
      <ReportBugModalCard
        onClick={(e) => e.stopPropagation()}
        data-cy="report-bug-modal"
      >
        <ReportBugModalHeader>
          <ReportBugModalCloseButton
            whileTap={{ scale: 0.8 }}
            onClick={handleCloseModal}
            data-testid="report-bug-modal-close-btn"
          >
            <ReportBugModalCloseIcon fontSize="small" />
          </ReportBugModalCloseButton>
        </ReportBugModalHeader>
        <ReportBugModalContentContainer>
          <ReportBugModalImageContainer>
            <img
              src={BugImage}
              alt="homem com aplicações futuristas"
              data-testid="report-bug-modal-img"
            />
          </ReportBugModalImageContainer>
          <ReportBugModalTextContainer>
            <ReportBugModalTitle data-testid="report-bug-modal-title">
              Encontrou um Erro?
            </ReportBugModalTitle>
            <ReportBugModalText>
              Sua atenção é fundamental! Se encontrar algum problema,
              clique no botão abaixo para nos informar. Sua ajuda é crucial para aprimorarmos
              nossa plataforma. Obrigado!
            </ReportBugModalText>
            <ReportBugModalButtonsContainer>
              <FormButton
                onClick={handleRedirectToBugReport}
                dataCy="report-bug-modal-report-btn"
              >
                Relatar erro
              </FormButton>
            </ReportBugModalButtonsContainer>
          </ReportBugModalTextContainer>
        </ReportBugModalContentContainer>
      </ReportBugModalCard>
    </ReportBugModalBackdrop>
  );
};

ReportBugModal.defaultProps = {
  url: 'https://suportesapron.atlassian.net/servicedesk/customer/portal/3/group/14/create/60',
};

export { ReportBugModal };
