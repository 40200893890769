import styled from 'styled-components';

export type Position = {
  top: number,
  left: number,
  side: string,
  width: number,
  height: number,
  vertical: string

};

export const WrapperContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const Container = styled.div`
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
`;

export const ContainerCenter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const ContainerLocalization = styled.div`
  width: 35%;

  img {
    width: 100%;
    height: 304px;
    border-radius: 10px;
    object-fit: cover;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    padding-bottom: 16px;
  }

  .style-see-localization {
    font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.blue._850.hex()};
  }

  .otaTitle {
    margin-top: 32px;
    margin-bottom: 16px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0;
`;

export const ContainerDetails = styled.div`
  width: 58%;

  > span {
    font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    padding-top: 20px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  > p {
    font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    padding-top: 16px;

    &.owner {
      cursor: pointer;
      padding-top: 0;
      padding-bottom: 16px;
    }
  }
`;

export const ModalOwner = styled.div<{
  openModalOwner: boolean;
  positionModal: Position;
}>`
  display: ${({ openModalOwner }) => (openModalOwner ? 'flex' : 'none')};
  flex-direction: column;
  width: 300px;
  height: 100px;
  border-radius: 10px;
  position: absolute;
  z-index: 999;
  border: 1px solid ${({ theme }) => theme.palette.grey._420.hex()};
  padding: 15px;
  justify-content: center;
  background-color: white;

  left: ${({ positionModal }) => (positionModal.side === 'left' ? `${positionModal.left}px` : 'auto')};
  right: ${({ positionModal }) => (positionModal.side === 'right' ? `${positionModal.width - 150 - positionModal.left}px` : 'auto')};
  top: ${({ positionModal }) => (positionModal.vertical === 'top' ? `${positionModal.top + positionModal.width / 90}px` : 'auto')};
  bottom: ${({ positionModal }) => (positionModal.vertical === 'bottom' ? `${positionModal.height - positionModal.top - positionModal.width / 15}px` : 'auto')};

  .phoneNumber{
    padding-top: 5px;
    cursor: pointer;
    &:hover{
      background-color: rgba(240,240,240,0.6);
    }
  }
`;

export const ModalHost = styled.div<{
  openModalHost: boolean;
  positionModal: Position;
}>`
  display: ${({ openModalHost }) => (openModalHost ? 'flex' : 'none')};
  flex-direction: column;
  width: 300px;
  height: 100px;
  border-radius: 10px;
  background-color: white;
  position: absolute;
  z-index: 999;
  border: 1px solid ${({ theme }) => theme.palette.grey._420.hex()};
  padding: 15px;
  justify-content: center;

  left: ${({ positionModal }) => (positionModal.side === 'left' ? `${positionModal.left}px` : 'auto')};
  right: ${({ positionModal }) => (positionModal.side === 'right' ? `${positionModal.width - 150 - positionModal.left}px` : 'auto')};
  top: ${({ positionModal }) => (positionModal.vertical === 'top' ? `${positionModal.top + positionModal.width / 90}px` : 'auto')};
  bottom: ${({ positionModal }) => (positionModal.vertical === 'bottom' ? `${positionModal.height - positionModal.top - positionModal.width / 15}px` : 'auto')};

  .phoneNumber{
    padding-top: 5px;
    cursor: pointer;
    &:hover{
      background-color: rgba(240,240,240,0.6);
    }
  }
`;

export const ContentRow = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._420.hex()};
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  p {
    margin-left: 5px;
    font-family: ${({ theme }) => theme.fonts.familys._6} !important;
    color: ${({ theme }) => theme.palette.grey._720.hex()};
  }

  svg {
    fill: ${({ theme }) => theme.palette.blue._850.hex()};
  }

`;

export const WrapperModalOwner = styled.div<{
  openModalOwner: boolean;
}>`
  display: ${({ openModalOwner }) => (openModalOwner ? 'flex' : 'none')};
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  position: fixed;
`;

export const WrapperModalHost = styled.div<{
  openModalHost: boolean;
}>`
  display: ${({ openModalHost }) => (openModalHost ? 'flex' : 'none')};
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  position: fixed;
`;
export const Status = styled.p`
  color: ${({ theme }) => theme.palette.green.main.hex()};
  font-size: 14px !important;
  font-weight: 700 !important;
  display: flex;
  align-items: center;

  &:before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.palette.green.main.hex()};
    margin-right: 10px;
  }
`;

export const CardsValues = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  padding-top: 16px;
  border-top: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const CardInfo = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  padding: 16px;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  border-radius: 10px;

  > span {
    font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
    color: ${({ theme }) => theme.palette.grey._720.hex()};
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
    color: ${({ theme }) => theme.palette.grey._720.hex()};
    font-size: 22px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    padding-left: 5px;
  }
`;

export const Alignment = styled.div`
  height: 100px;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
`;

export const PropertyBeds = styled.ul`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
  color: ${({ theme }) => theme.palette.grey._900.hex()};
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0;
  margin-left: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  list-style: none;

  li {
    font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0;
    padding-top: 20px;
    display: flex;
    align-items: baseline;

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: ${({ theme }) => theme.palette.grey._720.hex()};
      margin-right: 10px;
    }
  }
`;

export const ContainerMoreInfo = styled.div`
  margin-top: 32px;

  h1 {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  }

  p,
  li {
    font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    padding-top: 20px;
  }
`;

export const ContainerColuns = styled.div`
  display: flex;
`;

export const ContentComment = styled.div`
  background-color: ${({ theme }) => theme.palette.grey._240.hex()};
  border-radius: 16px;
  height: auto;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 60px;
  padding-bottom: 18px;
  padding-left: 60px;
  padding-right: 60px;

  h1 {
    padding-top: 18px;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-size: 24px;
    font-weight: 700;
  }
`;

export const MoreComments = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 15px;

  img {
    margin-left: 9px;
  }

  .more {
    transform: rotate(0deg);
  }

  .less {
    transform: rotate(180deg);
  }
`;

export const DividerComment = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.blue._850.hex()};
  margin-top: 8px;
  margin-bottom: 20px;
`;

export const ADDComment = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  height: 90px;

  form {
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

`;

export const InitialsName = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.palette.blue._850.hex()};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.white.main.hex()};
  font-weight: 700;
  font-size: 20px;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  margin-left: 1rem;

  &.user {
    margin-right: 10px;
  }
`;
export const InputComment = styled.input`
  margin-left: 10px;
  height: 50px;
  width: 95%;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  color: ${({ theme }) => theme.palette.grey._600.hex()};
  font-weight: 400;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  padding-left: 15px;
  padding-right: 100px;
`;

export const HeaderInformation = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  margin-bottom: 16px; 
`;

export const ButtonChange = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 1rem;
  border-radius: 10px;
  border: none;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  background-color: ${({ theme }) => theme.palette.blue._850.hex()};
  font-weight: 600;
  color: ${({ theme }) => theme.palette.white.main.hex()};
  cursor: pointer;
  -webkit-box-shadow: 1px 3px 12px 2px rgba(0,0,0,0.35);
  box-shadow: 1px 3px 12px 2px rgba(0,0,0,0.35);

  &:hover {
    background-color: ${({ theme }) => theme.palette.blue._200.hex()};
  }
`;

export const OtaContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-bottom: 32px;
  
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      img {
        border: 1px solid ${({ theme }) => theme.palette.grey._150.hex()};
        object-fit: contain;
        height: 25px;
        width: 100%;
      }
    }
  }

  p {
    font-size: 1.5rem;
  }
`;

export const CopyContainer = styled.div`
  width: 50px;
  height: 50px;
  margin-left: 5px;

  img {
    width: 25px;
    height: 25px;

    &:hover {
      transform: translateY(-5px);
      filter: brightness(0.5);
    }
  }
`;

export const ConstructionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  img {
    height: 60px;
    margin-right: 40px;
    margin-left: -40px;
  }
  h3 {
    font-family: ${({ theme }) => theme.fonts.familys._6};
    color: ${({ theme }) => theme.palette.grey._550.hex()};
    font-size: 2rem;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
`;

export const CopyButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  transition-duration: 200ms;

  img {
    height: 28px;
    width: 28px;
  }

  &:hover {
    transform: translateY(-5px);
  }
`;

export const ButtonOwnerInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.blue._850.hex()};
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  margin-bottom: 8px;

  > p {
    font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.white.main.hex()} !important;
  }
`;

export const ButtonHostInfo = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.blue._850.hex()};
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  margin-bottom: 8px;

  > p {
    font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.white.main.hex()} !important;
  }
`;

export const Wrapper = styled.div`
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    p {
      color: #62656F;
      font-size: 22px;;
    }
    span {
      white-space: nowrap;
    }
`;

export const Divider = styled.div`
    height: 80%;
    width: 2px;
    background: ${({ theme }) => theme.palette.grey._280.hex()};
    margin: 0 8px;
    border-radius: 8px;
`;
