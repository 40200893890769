import styled from 'styled-components';
import { ContainerCheckbox } from '../../styles';
import InputCheckBox from '../CheckBox';
import CheckBoxList from '../CheckBoxList';
import { Text } from '../../../../components/Text';
import { comodidades } from '../../mock';

const ContainerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`;

const Column = styled.div<{ width?: string }>`
  flex: 1 1 ${({ width }) => (width ? `${width}` : '25%')};
  max-width: ${({ width }) => (width ? `${width}` : '25%')};
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex: 1 1 100%;
    max-width: 100%;
  }
`;

const WrapperCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 16px;
`;

const AccommodationAmenities = ({ checked, labelName, onChange }: any) => (
  <>
    <div style={{ marginBottom: '32px' }}>
      <CheckBoxList options={comodidades} />
    </div>

    <ContainerWrap>
      <Column>
        <WrapperCheckbox>
          <Text $color="#000" $fontWeight="500" $fontSize="16">
            Check-in/check-out expressos
          </Text>
          <ContainerCheckbox>
            <InputCheckBox checked labelName="Sim" />
            <InputCheckBox checked={false} labelName="Não" />
          </ContainerCheckbox>
        </WrapperCheckbox>
      </Column>
      <Column>
        <WrapperCheckbox>
          <Text $color="#000" $fontWeight="500" $fontSize="16">
            Recepção 24 Horas
          </Text>
          <ContainerCheckbox>
            <InputCheckBox checked={false} labelName="Sim" />
            <InputCheckBox checked={false} labelName="Não" />
          </ContainerCheckbox>
        </WrapperCheckbox>
      </Column>
      <Column>
        <WrapperCheckbox>
          <Text $color="#000" $fontWeight="500" $fontSize="16">
            Estacionamento
          </Text>
          <ContainerCheckbox>
            <InputCheckBox checked={false} labelName="Sim" />
            <InputCheckBox checked={false} labelName="Não" />
          </ContainerCheckbox>
        </WrapperCheckbox>
      </Column>
      <Column>
        <WrapperCheckbox>
          <Text $color="#000" $fontWeight="500" $fontSize="16">
            Tipo de Estacionamento
          </Text>
          <ContainerCheckbox>
            <InputCheckBox checked={false} labelName="Local" />
            <InputCheckBox checked={false} labelName="Proxímo" />
          </ContainerCheckbox>
        </WrapperCheckbox>
      </Column>
    </ContainerWrap>
    <ContainerWrap>
      <Column>
        <WrapperCheckbox>
          <Text $color="#000" $fontWeight="500" $fontSize="16">
            Reserva do estacionamento
          </Text>
          <ContainerCheckbox>
            <InputCheckBox checked={false} labelName="Necessário" />
            <InputCheckBox checked={false} labelName="Não é necessário " />
          </ContainerCheckbox>
        </WrapperCheckbox>
      </Column>
      <Column>
        <WrapperCheckbox>
          <Text $color="#000" $fontWeight="500" $fontSize="16">
            Área de estacionamento
          </Text>
          <ContainerCheckbox>
            <InputCheckBox checked={false} labelName="Público" />
            <InputCheckBox checked={false} labelName="Particular " />
          </ContainerCheckbox>
        </WrapperCheckbox>
      </Column>
      <Column>
        <WrapperCheckbox>
          <Text $color="#000" $fontWeight="500" $fontSize="16">
            Taxa de estacionar
          </Text>
          <ContainerCheckbox>
            <InputCheckBox checked={false} labelName="Grátis" />
            <InputCheckBox checked={false} labelName="Custo adicional " />
          </ContainerCheckbox>
        </WrapperCheckbox>
      </Column>
      <Column>
        <WrapperCheckbox>
          <Text $color="#000" $fontWeight="500" $fontSize="16">
            Internet a cabo
          </Text>
          <ContainerCheckbox>
            <InputCheckBox checked={false} labelName="Sim" />
            <InputCheckBox checked={false} labelName="Não" />
          </ContainerCheckbox>
        </WrapperCheckbox>
      </Column>
    </ContainerWrap>
    <ContainerWrap>
      <Column>
        <WrapperCheckbox>
          <Text $color="#000" $fontWeight="500" $fontSize="16">
            Internet WIFI
          </Text>
          <ContainerCheckbox>
            <InputCheckBox checked={false} labelName="Sim" />
            <InputCheckBox checked={false} labelName="Não" />
          </ContainerCheckbox>
        </WrapperCheckbox>
      </Column>
      <Column width="50%">
        <WrapperCheckbox>
          <Text $color="#000" $fontWeight="500" $fontSize="16">
            Onde está disponível?
          </Text>
          <div style={{ display: 'flex' }}>
            <div>
              <InputCheckBox checked={false} labelName="Em todo o estabelecimento" />
              <InputCheckBox checked={false} labelName="Apenas em áreas públicas" />
              <InputCheckBox checked={false} labelName="Business Center" />
            </div>
            <div>
              <InputCheckBox checked={false} labelName="Todas as acomodações" />
              <InputCheckBox checked={false} labelName="Em algumas acomodações" />
            </div>
          </div>
        </WrapperCheckbox>
      </Column>
      <Column>
        <WrapperCheckbox>
          <Text $color="#000" $fontWeight="500" $fontSize="16">
            Aplicação da Cobrança
          </Text>
          <ContainerCheckbox>
            <InputCheckBox checked={false} labelName="Grátis" />
            <InputCheckBox checked={false} labelName="Custo adicional " />
          </ContainerCheckbox>
        </WrapperCheckbox>
      </Column>
    </ContainerWrap>
  </>
);
export default AccommodationAmenities;
