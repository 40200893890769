import styled from 'styled-components';

const MAX_SIZE_MOBILE = 900; // 900px

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  max-width: 400px;
  width: 100%;
  height: auto;
  border-radius: 10px;
  padding: 16px 16px 0 16px;
  background-color: white;

  @media(max-width: ${MAX_SIZE_MOBILE}px){
    max-width: 90%;
  }
`;

export const ButtonClose = styled.button`
  width: 30px;
  color: ${({ theme }) => theme.palette.orange.main.hex()};
  background: transparent;
  border: none;
  font-size: 10px;

  :hover {
      cursor: pointer;
      filter: brightness(0.9);
      -webkit-transform: scale(1.3);
      -ms-transform: scale(1.3);
      transform: scale(1.3);
    }
`;

export const HeaderModalNewBankAccount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: ${({ theme }) => theme.palette.blue._970.hex()};
  }

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  @media(max-width: ${MAX_SIZE_MOBILE}px){
    h4 {
      font-size: 20px;
    }
  }
`;

export const ContentInputs = styled.div`
  position: relative;
  overflow-y: scroll;
  width: 100%;

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ButtonSave = styled.button`
  background-color: ${({ theme }) => theme.palette.blue._850.hex()};

  width: 50%;
  height: 40px;

  border-radius: 10px;
  border: none;

  color: white;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;

  :hover {
    filter: brightness(0.8);
    cursor: pointer;
  }
`;

export const ButtonCancel = styled.button`
  background: #EBEBF5;
  width: 50%;
  height: 40px;

  border-radius: 10px;
  border: 1px solid #D9DCDF;

  color: #B5B5B5;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;

  :hover {
    filter: brightness(0.9);
    cursor: pointer;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  height: 100px;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
