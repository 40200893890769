import styled from 'styled-components';

const MAX_SIZE_MOBILE = 900; // 900px

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  border-radius: 10px;
  margin-top: 16px;  
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  display: flex;
  gap: 16px;

  .account-user-content {
    padding-right: 16px;
    border-right: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  }

  @media(max-width: ${MAX_SIZE_MOBILE}px){
    flex-direction: column;
    align-items: flex-start;

    .account-user-content {
      padding-right: 0px;
      border-right: 0;
    }
  }
`;

export const TagMain = styled.div`
  width: fit-content;
  height: fit-content; 
  text-align: center;
  padding: 8px;
  background: ${({ theme }) => theme.palette.green._300.hex()};
  border-radius: 8px;
  color: white;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

export const Text = styled.p`
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
  
  @media(max-width: ${MAX_SIZE_MOBILE}px){
    color: ${({ theme }) => theme.palette.grey._560.hex()} !important;
  }
`;

export const TextLight = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
  padding: 2px 0;
`;
