import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  background: #ffffff;
  border-radius: 10px;
  padding: 6px 16px;
  margin-bottom: 5px;
  cursor: pointer;

  &:hover {
    background: #e2fff6;
  }
`;
export const Name = styled.div`
  width: 30%;
  min-width: 250px;
  padding-right: 60px;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  display: flex;
  align-items: center;

  color: #151b26;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
  justify-content: center;

  width: 20%;
  border-left: 2px solid #e7eaf1;

  &.qtd-property {
    width: 8%;
    min-width: 70px;
    > p {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.email {
    width: 23%;
    min-width: 220px;
    > p {
      width: 80%;
    }
  }
  &.address {
    width: 20%;
    min-width: 220px;
    > p {
      width: 80%;
    }
  }
  &.contract {
    text-align: center;
    width: 13%;
  }

  &.phone {
    width: 18%;
    min-width: 200px;
  }

  &.code {
    text-align: center;
    width: 16%;
    min-width: 100px;
  }
  &.model {
    text-align: center;
    width: 16%;
    min-width: 110px;
  }

  p {
    width: 50%;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  svg {
    color: #909aac;
    margin-left: 10px;
  }
`;
