/* eslint-disable max-len */
import { v4 as uuid } from 'uuid';
import { Moment } from 'moment';
import { addDays, subDays } from 'date-fns';
import request from '../request';

import {
  BlockFormData,
  PaymentStatus,
  Reservation,
  ReservationFormData,
  ReservationStatus,
  ReservationPatchProps,
  ReservationReallocateProps,
  ReservationConciliate,
} from './types';

import { ReservationById } from '../../types/Reservations';
import { Mode } from '../../context/ViewMode/type';
import { phoneToNumber } from '../../utils/InputMask/Number';
import { formatedDateOfRequest } from '../../utils/Formatter';

export const postReservation = async (values: ReservationFormData) => {
  let guestId = values.guest;

  if (values.isNewGuest) {
    const { data: guest } = await request.post('/account/guest/', {
      user: {
        first_name: values.guest,
        last_name: values.lastName,
        email: values.email,
        phone_number1: phoneToNumber(values.phone1),
        birth_date: formatedDateOfRequest(values.born.toString()),
        gender: values.gender,
        main_role: 'Guest',
        is_individual: values.typePerson === 'individual',
        cpf: values.cpf,
        cnpj: values.cnpj === '' ? null : values.cnpj,
        corporate_name: values.corporateName,
        trading_name: values.tradingName,
        main_address: {
          street: values.street,
          number: values.number,
          neighborhood: values.neighborhood,
          city: values.city,
          state: values.state,
          postal_code: values.zipCode,
          country: values.country,
        },
      },
    });

    guestId = guest.id;
  }

  await Promise.all(
    (values.properties || []).map((property) => request.post('/calendar/reservations/', {
      code: uuid(),
      guest: guestId,
      check_in_date: formatedDateOfRequest(values.checkInDate.toString()),
      check_out_date: formatedDateOfRequest(values.checkOutDate.toString()),
      total_price: values.totalValue,
      recommended_by: values.indication,
      comment: values.notes,
      paid_amount: values.totalPaid,
      ota: values.ota.id,
      property: property.id,
      adult_guest_quantity: values.adults,
      child_guest_quantity: values.children,
      status: 'Active',
      has_pet: values.hasPet ? values.petsNumber : 0,
      owner_atm: property.owners[0].id,
    })),
  );
};

export const postReservationV2 = async (values: ReservationFormData) => {
  let guestId = values.guest;

  if (values.isNewGuest) {
    const { data: guest } = await request.post('/account/guest/', {
      user: {
        first_name: values.guest,
        last_name: values.lastName,
        email: values.email,
        phone_number1: phoneToNumber(values.phone1),
        birth_date: formatedDateOfRequest(values.born.toString()),
        gender: values.gender,
        main_role: 'Guest',
        is_individual: values.typePerson === 'individual',
        cpf: values.cpf,
        cnpj: values.cnpj === '' ? null : values.cnpj,
        corporate_name: values.corporateName,
        trading_name: values.tradingName,
        main_address: {
          street: values.street,
          number: values.number,
          neighborhood: values.neighborhood,
          city: values.city,
          state: values.state,
          postal_code: values.zipCode,
          country: values.country,
        },
      },
    });

    guestId = guest.id;
  }

  const data = await request.post('/calendar/reservations/', {
    code: uuid(),
    guest: guestId,
    check_in_date: formatedDateOfRequest(values.checkInDate.toString()),
    check_out_date: formatedDateOfRequest(values.checkOutDate.toString()),
    total_price: values.totalValue,
    recommended_by: values.indication,
    comment: values.notes,
    paid_amount: values.totalPaid,
    ota: values.ota.id,
    property: values.propertyId,
    adult_guest_quantity: values.adults,
    child_guest_quantity: values.children,
    status: 'Active',
    has_pet: values.hasPet ? values.petsNumber : 0,
    owner_atm: values.owner_atm,
  });
  return data;
};

export const postBlocking = async (values: BlockFormData) => {
  await Promise.all(
    values.properties.map((property) => {
      let payload = {
        code: uuid(),
        check_in_date: formatedDateOfRequest(values.checkInDate.toString()),
        check_out_date: formatedDateOfRequest(values.checkOutDate.toString()),
        blocking_reason: values.blockingReason.value,
        comment: values.notes,
        property: property.id,
        status: 'Active',
      } as any;

      if (values?.guest) {
        payload = {
          ...payload,
          guest: {
            ...values.guest,
          },
        };
      }

      return request.post('/calendar/blocking/', payload);
    }),
  );
};

export const postHostBlocking = async (values: any) => {
  const payload = {
    code: uuid(),
    check_in_date: formatedDateOfRequest(values.checkInDate.toString()),
    check_out_date: formatedDateOfRequest(values.checkOutDate.toString()),
    blocking_reason: values.blockingReason.value,
    comment: values.notes,
    property: values.property,
    status: 'Active',
    ...(values.guest && { guest: { ...values.guest } }),
  };

  const { data } = await request.post('/calendar/blocking/', payload);

  return data;
};

const createBlockingByPreparationTime = (origin: Reservation, checkin: Date, checkout: Date) => {
  const blocking: Reservation = {
    ...origin,
    id: `${origin.id}-${checkin}-${checkout}`,
    blocking_reason: 'Preparation',
    is_blocking: true,
    check_in_date: checkin.toISOString().split('T')[0],
    check_out_date: checkout.toISOString().split('T')[0],
  };

  return blocking;
};

interface ConfigBase {
  mode: Mode | null;
}

type Config = Partial<ConfigBase>;

export const getReservations = async (
  dates: Moment[],
  properties: string[],
  config?: Config,
): Promise<Reservation[]> => {
  const userID = config?.mode?.user_id || null;

  const { data } = await request.get('/calendar/reservations/', {
    params: {
      check_in_date: dates[dates.length - 1].format('YYYY-MM-DD'),
      check_out_date: dates[0].format('YYYY-MM-DD'),
      listing: properties.join(','),
      user_id: userID,
    },
  });

  const response: Reservation[] = [...data];

  data.forEach((item: Reservation) => {
    const extraDayPreparation = item?.listing?.property?.extra_day_preparation ? item?.listing?.property?.extra_day_preparation : 0;

    if (!item.is_blocking && extraDayPreparation !== 0) {
      const checkinBefore = subDays(new Date(item.check_in_date), extraDayPreparation);
      const checkoutBefore = new Date(item.check_in_date);
      const blockingBefore = createBlockingByPreparationTime(item, checkinBefore, checkoutBefore);
      response.push(blockingBefore);

      const checkinAfter = new Date(item.check_out_date);
      const checkoutAfter = addDays(new Date(item.check_out_date), extraDayPreparation);
      const blockingAfter = createBlockingByPreparationTime(item, checkinAfter, checkoutAfter);
      response.push(blockingAfter);
    }
  });

  return response;
};

export const getPaymentStatus = (reservationStatus: ReservationStatus): PaymentStatus => {
  const status: Record<ReservationStatus, PaymentStatus> = {
    Active: 'fullyPaid',
    Concluded: 'fullyPaid',
    Canceled: 'canceled',
    'In progress': 'partiallyPaid',
    'Pre-booking': 'unpaid',
  };

  return status[reservationStatus];
};

export const putReservationStatus = async (
  data: Reservation,
  newStatus: ReservationStatus,
  newComment: string,
  id: number,
) => {
  await request.put(`/calendar/reservations/${id}/`, {
    ...data,
    guest: data.guest.id,
    status: newStatus,
    comment: newComment,
  });
};

export const postExtendReservation = async (newExtension: Reservation): Promise<Reservation> => {
  const { data } = await request.post('/calendar/reservations/', {
    ...newExtension,
    guest: newExtension.guest.id,
    ota: newExtension?.listing?.ota?.id,
    property: newExtension?.listing?.property?.id,
    original_reservation: newExtension?.id,
    check_in_date: formatedDateOfRequest(newExtension?.check_in_date),
    check_out_date: formatedDateOfRequest(newExtension?.check_out_date),
    paid_amount: newExtension?.paid_amount,
    total_price: newExtension?.total_price,
    is_early_extension: newExtension?.is_early_extension,
    is_late_extension: newExtension?.is_late_extension,
  });
  return data;
};

export const putFieldsOriginalReservation = async (
  id: number,
  data: Reservation,
  hasEarlyExtension: boolean,
  hasLateExtension: boolean,
  EarlyExtensionReservation: number | null,
  LateExtensionReservation: number | null,
) => {
  await request.put(`/calendar/reservations/${id}/`, {
    ...data,
    guest: data.guest.id,
    has_early_extension: hasEarlyExtension,
    has_late_extension: hasLateExtension,
    early_extension_reservation: EarlyExtensionReservation,
    late_extension_reservation: LateExtensionReservation,
  });
};

export const getReservationById = async (id: number): Promise<Reservation> => {
  const { data } = await request.get(`/calendar/reservations/${id}/`);
  return data;
};

export const patchReservation = async (id: number, data: ReservationPatchProps) => request.patch<Reservation>(`/calendar/reservations/${id}/`, {
  ...data,
});

export const putReservationReallocate = async (data: ReservationReallocateProps) => request.put<Reservation>('/reservations/relocate/', {
  ...data,
});

export const putReservationsConciliate = async (data: ReservationConciliate) => request.put('/reservations/conciliate/', {
  ...data,
});

export const getReservationDataById = async (
  checkIn: any,
  checkOut: any,
  propertyId?: any,
  statushost?: string,
) => {
  const { data } = await request.get(
    `/reservations/calendar?start_date=${checkIn}&end_date=${checkOut}&property_ids=${propertyId}&status=${statushost}`,
  );

  const response: any = [...data];

  data.forEach((item: any) => {
    if (!item.is_blocking && item?.property?.extra_day_preparation !== 0) {
      const checkinBefore = subDays(new Date(item.check_in_date), item?.property?.extra_day_preparation);
      const checkoutBefore = new Date(item.check_in_date);
      const blockingBefore = createBlockingByPreparationTime(item, checkinBefore, checkoutBefore);
      response.push(blockingBefore);

      const checkinAfter = new Date(item.check_out_date);
      const checkoutAfter = addDays(new Date(item.check_out_date), item?.property?.extra_day_preparation);
      const blockingAfter = createBlockingByPreparationTime(item, checkinAfter, checkoutAfter);
      response.push(blockingAfter);
    }
  });

  return response;
};

export const getReservationDetailsById = async (reservationId: number) => {
  const { data } = await request.get<ReservationById>(`reservations/calendar/${reservationId}`);

  return data;
};

export const getReservationPaymentsId = async (reservationId: number) => {
  const { data } = await request.get<ReservationById>(`reservations/calendar/${reservationId}/payments`);

  return data;
};
