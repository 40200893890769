import React from 'react';
import styled from 'styled-components';
import { Text } from '../Text';

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
`;

const Step = styled.div<{ active: boolean }>`
  background-color: ${({ active }) =>
    active ? 'rgba(0, 82, 254, 1)' : '#D9D9D9'};
  flex: 1;
  height: 4px;
  border-radius: 28px;
  position: relative;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    background-color: rgba(0, 82, 254, 1);
    right: -2px;
    top: 0;
  }
`;

interface StepComponentProps {
  activeStep: number;
}

const StepComponent: React.FC<StepComponentProps> = ({ activeStep }) => (
  <div>
    <StepContainer>
      <Step active={activeStep >= 1} />
      <Step active={activeStep >= 2} />
    </StepContainer>
    <Text $color="#0052FE" $fontSize="16">
      {activeStep === 1 ? 'Amenities de Endereço' : 'Amenities de Endereço'}
    </Text>
  </div>
);

export default StepComponent;
