import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (max-width: 1100px){
    grid-template-columns: 1fr;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  padding: 0 2rem;
  height: 100%;
`;
export const LeftContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  .two-columns {
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;

    .labelClass {
      font-weight: ${({ theme }) => theme.fonts.weights.normal} !important;
      font-family: ${({ theme }) => theme.fonts.familys._6};
    }

    .labelClassJustification {
      padding-top: 10px;
    }

    @media (max-width: 780px){
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }

  .one-column {
    grid-column-start: 1;
    grid-column-end: 2;
    margin-bottom: 1rem;

    .labelClass {
      font-weight: ${({ theme }) => theme.fonts.weights.normal} !important;
      font-family: ${({ theme }) => theme.fonts.familys._6};
    }
  }

  .switch {
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${({ theme }) => theme.palette.grey._450.hex()};;
    border-radius: 5px;
    padding: 1rem;
    width: 100%;

    h2 {
      font-weight: ${({ theme }) => theme.fonts.weights.normal};
    }

    div {
      justify-self: flex-end;
    }

  }
`;

export const RightContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  .two-columns {
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    grid-template-columns: repeat(2,1fr);
    gap: 1rem;
    margin-bottom: 1rem;

    .labelClass {
      font-weight: ${({ theme }) => theme.fonts.weights.normal} !important;
      font-family: ${({ theme }) => theme.fonts.familys._6};
    }

    .franchise-fee-rebate-percentage {
      width: 50%;
    }
    
    .franchise-fee-updated-at {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      div {
        display: flex;
        align-items: center;
      }
      
      strong, svg {
        color: ${({ theme }) => theme.palette.blue._250.hex()};
      }

      span {
        margin-top: 0.5rem;
      }

      svg {
        margin-right: 0.5rem;
      }
    }

    @media (max-width: 780px){
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }

  .one-column {
    grid-column-start: 1;
    grid-column-end: 2;
    margin-bottom: 1rem;

    .labelClass {
      font-weight: ${({ theme }) => theme.fonts.weights.normal} !important;
      font-family: ${({ theme }) => theme.fonts.familys._6};
    }

    .franchise-fee-rebate-percentage {
      width: 100%;
    }
    
    .franchise-fee-updated-at {
      display: none;
    }
  }

  .switch {
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${({ theme }) => theme.palette.grey._450.hex()};;
    border-radius: 5px;
    padding: 1rem;
    width: 100%;

    h2 {
      font-weight: ${({ theme }) => theme.fonts.weights.normal};
    }

    div {
      justify-self: flex-end;
    }

  }
`;

export const Title = styled.h2`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  color: #001840;
  margin: 16px 0 16px 20px;
  line-height: 32px;
`;

export const SubTitle = styled.h3`
  font-size: 1.375rem;
  line-height: 2rem;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  color: #001840;
  font-weight: 400;
  padding: 0 2rem;
  margin: 2rem 0;
`;

export const PasswordText = styled.p`
  color: #969696;
  font-size: 10px;
  font-style: normal;
  margin: 8px 0 1rem 0;
  font-weight: 500;
  line-height: 1rem;
  text-align: start;
`;

export const Box = styled.div`
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  padding: 1rem 0.5rem;
`;

export const RebatePercentageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 1rem;

  p {
    width: 92%;
    color: #FF9900;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-size: 1.1rem;
    line-height: 19.36px;
  }
`;

export const RadioButtonContainer = styled.div`
  width: 100%;
  max-width: 250px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Spacing = styled.div`
  margin: 8px 0;
`;

export const Divider = styled.div`
  width: 100%;
  height: 20px;
  border-top: 1px solid #C2C8F1 !important;
  margin: 16px 0 8px 24px;
`;
