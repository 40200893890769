import React, { useState } from 'react';
import styled from 'styled-components';
import { useMobile } from '../../../../hooks/useMobile/useMobile';
import InputCheckBox from '../CheckBox';
import Pagination from '../Pagination';

const Container = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;

  ::-webkit-scrollbar {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
`;

const Column = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const CheckboxWrapper = styled.div<{ isFull: any }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${(props: any) => (props.isFull ? 'flex-grow: 1;' : 'flex-grow: 0;')}

  label {
    white-space: normal;
  }
`;

interface Props {
  options: string[];
}

const CheckBoxList = ({ options }: Props) => {
  const { isMobile } = useMobile();
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 20;

  const totalPages = Math.ceil(options.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const currentItems = options.slice(startIndex, startIndex + itemsPerPage);

  return (
    <>
      <h1 style={{ margin: '16px 0' }}>Amenities básicas</h1>
      <Container>
        {!isMobile ? (
          Array.from({ length: Math.ceil(options.length / 20) }).map((_, columnIndex) => {
            const itemsInColumn = options.slice(columnIndex * 20, (columnIndex + 1) * 20);
            return (
              <Column key={`${_}`}>
                <CheckboxWrapper isFull={itemsInColumn.length === 20}>
                  {itemsInColumn.map((label) => (
                    <InputCheckBox key={label} checked={false} labelName={label} />
                  ))}
                </CheckboxWrapper>
              </Column>
            );
          })
        ) : (
          <Column>
            <CheckboxWrapper isFull={currentItems.length === 20}>
              {currentItems.map((label) => (
                <InputCheckBox key={label} checked={false} labelName={label} />
              ))}
            </CheckboxWrapper>
          </Column>
        )}
      </Container>

      {isMobile && totalPages > 1 && (
        <Pagination
          totalPages={totalPages}
          handleChangePage={(page: number) => setCurrentPage(page)}
        />
      )}
    </>
  );
};

export default CheckBoxList;
