import styled from 'styled-components';
import Timeline from 'react-calendar-timeline';

const MAX_SIZE_MOBILE = 900; // 900 px

export const TimelineContainer = styled.div``;

export const Header = styled.div`
  display: flex;
  padding: 15px 16px;
  align-items: center;
  margin-bottom: 24px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
`;

export const FilterContainer = styled.div<{ widthWindow?: number }>`
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
  gap: 16px;
  margin-left: 16px;

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
   flex-direction: column;
   align-items: flex-start;
   max-width: ${({ widthWindow }) => (widthWindow ? `${widthWindow - 32}px` : '350px')};
  }

  .react-calendar {
        text-align: center;
        color: #949ba0;
        button {
          border: none;
          background-color: ${({ theme }) => theme.palette.white.main.hex()};
          cursor: pointer;
          padding: 0.5rem;
        }
        span {
          font-family: ${({ theme }) => theme.fonts.familys._6};
          font-weight: ${({ theme }) => theme.fonts.weights.bold};
          line-height: 20px;
        }
        abbr {
          font-family: ${({ theme }) => theme.fonts.familys._6};
        }
        .react-calendar__navigation {
          padding-bottom: 0.813rem;
          border-bottom: 1px solid
          ${({ theme }) => theme.palette.grey._400.hex()};
        }
        .react-calendar__navigation__next-button,
        .react-calendar__navigation__prev-button{
          padding: 0.5rem;
        }
        .react-calendar__navigation__label::first-letter {
          text-transform: uppercase;
        }
        .react-calendar__month-view__weekdays {
          margin: 0.5rem 0;
          font-family: ${({ theme }) => theme.fonts.familys._6};
          font-size: 14px;
          font-weight: ${({ theme }) => theme.fonts.weights.regular};

        }
        .tile-class {
          font-style: normal;
          font-size: 14px;
          font-weight: ${({ theme }) => theme.fonts.weights.regular};
          color: ${({ theme }) => theme.palette.blue._950.hex()};
          border: none;
          background-color: ${({ theme }) => theme.palette.white.main.hex()};
          margin: 0.4rem 0 0;
          cursor: pointer;
          height: 35px;
          width: 35px;
          transition: all 400ms;
          &:hover {
            color: ${({ theme }) => theme.palette.blue._950.hex()};
          }
        }
        .react-calendar__tile:enabled:hover {
          background: ${({ theme }) => theme.palette.grey._400.hex()};
          border-radius: 10px;
        }
        .react-calendar__tile--active {
          background: linear-gradient(180deg, #0D4BD0 0%, #215789 100%);
          color: white;
          border-radius: 10px;
          padding: 0.5rem;
          border: 2px solid ${({ theme }) => theme.palette.white.main.hex()};
        }

        .react-calendar__tile--now {
          background: green;
          color: white;
          border-radius: 10px;
        }
        .react-calendar__tile--hasActive{
          background: linear-gradient(180deg, #0D4BD0 0%, #215789 100%);
          color: white;
          border-radius: 10px;
        }
        .react-calendar__month-view__days__day--neighboringMonth {
          color: ${({ theme }) => theme.palette.grey._450.hex()};
        }
      }


`;

export const MultiSelectWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const MultiSelectContent = styled.div<{ widthWindow?: number }>`
  width: 250px;

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    width: ${({ widthWindow }) => (widthWindow ? `${widthWindow - 24}px` : '350px')};;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;

  button {
    display: flex;
    align-items: center;
    border: 1px solid #E05845;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: transparent;
    color: #E05845;
    cursor: pointer;
    font-size: 14px;
    height: 41px;
  }

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    margin-top: 4px;
    width: 100%;
  
    button {
      width: 100%;
      justify-content: center;
    }
  }

`;

export const StyledTimeline = styled(Timeline)`
  .rct-sidebar {
    position: sticky !important;
    top: 0 !important;
    background: white !important; /* opcional: para garantir que a sidebar tenha um fundo */
    z-index: 10 !important; /* opcional: para garantir que a sidebar fique acima de outros elementos */
  }
`;
export const Chip = styled.div`
  width: 180px !important;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 4px 9px;
  border-radius: 0px 37px 36px 0px;
  background: #F0F0F0;
`;

export const Label = styled.label`
  font-size: 1rem !important;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.blue._970.hex()} !important;
  text-align: start;
  margin-right: 0.5rem;
  padding-top: 0.7rem;
  
  @media (max-width: 500px) {
    font-size: 0.9rem !important;
  }
`;
