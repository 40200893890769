import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import {
  GetOwnersData,
  PlanOptions,
  PropertyDetailsResponse,
} from '../../../../services/InsertData/types';

import { Container, Label } from './styles';
import { compareList } from '../../../../utils/Sorting';
import { onlyNumbers } from '../../../../utils/Formatter';
import { UseFormik } from '../../../../utils/Formik/types';
import { getHosts } from '../../../../services/Host/request';
import { RequestHost } from '../../../../services/Host/types';
import { SelectOption } from '../../../SimpleSelect/SimpleSelect';
import { getOwners } from '../../../../services/InsertData/request';
import { getAddressByCep } from '../../../../services/Address/request';
import { SelectProps } from '../../../DropdownAutocomplete/DropdownAutocomplete';

import Category from '../Category';
import TextField from '../../../TextField';
import CodeGenerator from './CodeGenerator';
import DatePicker from '../../../DatePicker';
import SimpleSelect from '../../../SimpleSelect';
import StateSelect from '../../../SimpleSelect/StateSelect';
import CountrySelect from '../../../SimpleSelect/CountrySelect';
import DropdownAutocomplete from '../../../DropdownAutocomplete';
import Checkbox from './Checkbox/Checkbox';
import ControlledSwitchButton from '../../../ControlledSwitchButton';
import { useToast } from '../../../../context/ToastContext';

const propertyStatusOptions: SelectOption[] = [
  { value: 'Active', valueLabel: 'Ativo' },
  { value: 'Inactive', valueLabel: 'Inativo' },
  { value: 'Onboarding', valueLabel: 'Onboarding' },
  { value: 'Closed', valueLabel: 'Contrato assinado' },
];

const propertyTypeOptions: SelectOption[] = [
  { value: 'House', valueLabel: 'Casa' },
  { value: 'Hotel', valueLabel: 'Hotel' },
  { value: 'Apartment', valueLabel: 'Apartamento' },
];

type PropertyType = 'House' | 'Apartment' | 'Hotel';
type PropertyStatus = 'Active' | 'Inactive' | 'Onboarding' | 'Closed';
// type UploadFile = 'CONTRAC' | 'INSURANCE' | 'PROFESSIONAL-INSPECTION' | 'HOST-INSPECTION' | ''
interface InsertDataPropertyFormik {
  property_id?: number;
  code: string;
  singleBedQuantity?: number;
  doubleBedQuantity?: number;
  queenBedQuantity?: number;
  kingBedQuantity?: number;
  singleSofaBedQuantity?: number;
  doubleSofaBedQuantity?: number;
  pillowQuantity?: number;
  bedroomQuantity?: number;
  bathroomQuantity?: number;
  lavatoryQuantity?: number;
  boundAmount?: string;
  guestCapacity?: number;
  propertyType?: PropertyType;
  status?: PropertyStatus;
  inactivationDate?: Date;
  contractStartDate?: Date;
  newContractStartDate?: Date;
  contractEndDate?: Date;
  street: string;
  number?: string;
  complement?: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  host?: number;
  owners?: number;
  changeContractStartDate?: boolean;
  hasNewOwner?: boolean;
  cleaningFee: string;
  comissionFee: string;
  categoryLocationId?: string;
  categoryLocationCode?: string;
  category: number;
  location: number;
  plan: PlanOptions;
  hostReservationComissionFee?: number;
  extraDayPreparation?: number;
}

interface FormAddPropertyProps {
  formik?: UseFormik<InsertDataPropertyFormik>;
  propertyDetails: PropertyDetailsResponse | undefined;
  isChurnChecked: boolean;
  setIsChurnChecked: Dispatch<SetStateAction<boolean>>;
  isLoadingProperty: boolean;
  isEditingForm: boolean;
}

const FormAddProperty = ({
  formik,
  propertyDetails,
  isChurnChecked,
  setIsChurnChecked,
  isLoadingProperty,
  isEditingForm = false,
}: FormAddPropertyProps) => {
  const toast = useToast();
  const [hosts, setHosts] = useState<RequestHost[]>([]);
  const [ownersList, setOwnersList] = useState<GetOwnersData[]>([]);

  const ownersIds: string[] = [];
  const ownersNames: string[] = [];

  const getHostsRequest = async () => {
    const hostsResults = await getHosts();
    setHosts(hostsResults);
  };

  const getSelectOwners = async () => {
    const results = await getOwners();
    setOwnersList(results);
  };

  const handleGetHost = (): SelectProps[] => hosts.map((host) => (
    {
      optionText: `${host.name}`,
      optionValue: `${host.id}`,
    }
  )).sort((a, b) => compareList(a.optionText, b.optionText));

  const handleGetOwner = (): SelectProps[] => {
    ownersList.forEach((owner) => {
      if (owner.user.trading_name === '') {
        ownersIds.push(`${owner.id}`);
        ownersNames.push(`${owner.user.first_name} ${owner.user.last_name}`);
      } else {
        ownersIds.push(`${owner.id}`);
        ownersNames.push(`${owner.user.trading_name}`);
      }
    });

    return ownersNames
      .map((name, index) => ({
        optionText: name,
        optionValue: ownersIds[index],
      }))
      .sort((a, b) => compareList(a.optionText, b.optionText));
  };

  async function handleNewAddress() {
    if (formik) {
      const response = await getAddressByCep(onlyNumbers(formik.values.postalCode));

      if ('erro' in response) {
        formik.setFieldError('postalCode', 'O cep não é válido!');
      } else {
        const {
          bairro, complemento, localidade, logradouro, uf,
        } = response;
        formik.setFieldValue('neighborhood', bairro);
        formik.setFieldValue('complement', complemento);
        formik.setFieldValue('city', localidade);
        formik.setFieldValue('street', logradouro);
        formik.setFieldValue('state', uf);
        formik.setFieldValue('country', 'Brasil');
      }
    }
  }

  useEffect(() => {
    if (formik) {
      if (formik?.values.postalCode.length >= 9) {
        handleNewAddress();
      }
    }
  }, [formik?.values.postalCode]);

  useEffect(() => {
    getHostsRequest();
    getSelectOwners();
  }, []);

  const getOwnerSelected = (ownerId: number) => {
    const index = ownersIds.findIndex((id) => Number(id) === ownerId);
    return ownersNames?.[index] || '';
  };

  const getHostSelected = (hostId: number) => {
    const hostFiltered = hosts.filter((host) => host.id === hostId);
    return hostFiltered?.[0]?.name || '';
  };

  useEffect(() => {
    if (propertyDetails) {
      formik?.setFieldValue('postalCode', propertyDetails?.address?.postal_code);
      formik?.setFieldValue('number', propertyDetails?.address?.number);
    }
  }, [propertyDetails]);

  function handleCheckChurnCheckbox() {
    setIsChurnChecked((prevState) => !prevState);
  }

  return (
    <Container>
      <div className="two-columns">
        <div>
          <DropdownAutocomplete
            required
            label="Proprietário"
            id="owners"
            options={handleGetOwner()}
            formik={formik}
            placeholder={formik?.values?.owners ? `${getOwnerSelected(formik?.values?.owners)}` : 'Digite aqui...'}
            labelClassName="labelClass"
            disabled={isEditingForm}
          />
          {isEditingForm && (
            <>
              <div style={{ marginTop: '8px' }} />
              <div>
                <Label>
                  Houve troca de proprietário?
                </Label>
                <div style={{ marginBottom: '4px' }} />
                <ControlledSwitchButton
                  checked={formik?.values?.hasNewOwner}
                  handleChange={() => {
                    if (!formik?.values?.property_id) {
                      toast.error('Selecione um imóvel');
                      return;
                    }
                    formik?.setFieldValue('hasNewOwner', !formik?.values?.hasNewOwner);
                  }}
                />
              </div>
            </>
          )}
        </div>
        <DropdownAutocomplete
          required
          label="Anfitrião"
          id="host"
          options={handleGetHost()}
          formik={formik}
          placeholder={formik?.values?.host ? `${getHostSelected(formik?.values?.host)}` : 'Digite aqui...'}
          labelClassName="labelClass"
          disabled={isEditingForm}
        />
      </div>
      <div className="two-columns">
        <TextField
          labelClassName="labelClass"
          id="postalCode"
          label="Código postal (CEP)"
          placeholder="Digite aqui..."
          mask="cep"
          formik={formik}
          value={formik?.values.postalCode}
          required
          disabled={isLoadingProperty}
        />
      </div>
      <div className="one-column">
        <TextField
          labelClassName="labelClass"
          id="street"
          label="Endereço/Rua, etc..."
          placeholder="Digite aqui..."
          required
          value={formik?.values.street}
          formik={formik}
          disabled={isLoadingProperty}
        />
      </div>
      <div className="two-columns">
        <TextField
          labelClassName="labelClass"
          id="number"
          label="Número"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.number}
          disabled={isLoadingProperty}
        />
        <TextField
          labelClassName="labelClass"
          id="complement"
          label="Complemento"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.complement}
          disabled={isLoadingProperty}
        />
      </div>
      <div className="two-columns">
        <TextField
          required
          labelClassName="labelClass"
          id="neighborhood"
          label="Bairro"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.neighborhood}
          disabled={isLoadingProperty}
        />
        <TextField
          required
          labelClassName="labelClass"
          id="city"
          label="Cidade"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.city}
          disabled={isLoadingProperty}
        />
      </div>
      <div className="two-columns">
        <StateSelect
          labelClassName="labelClass"
          id="state"
          label="Estado"
          formik={formik}
          required
          disabled={isLoadingProperty}
        />
        <CountrySelect
          labelClassName="labelClass"
          id="country"
          label="País"
          valueIsFullName
          formik={formik}
          required
          disabled={isLoadingProperty}
        />
      </div>
      <div className="two-columns">
        {formik && <Category formik={formik} />}
        <TextField
          labelClassName="labelClass"
          id="hostReservationComissionFee"
          label="Comissão de reserva do anfitrião"
          required
          type="number"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values?.hostReservationComissionFee}
        />
      </div>
      <div className="one-column">
        <CodeGenerator formik={formik} required />
      </div>
      <div className="two-columns">
        <TextField
          required
          labelClassName="labelClass"
          id="comissionFee"
          label="Taxa de comissão (%)"
          placeholder="0%"
          formik={formik}
          value={formik?.values?.comissionFee}
          type="number"
          disabled={isLoadingProperty}
        />
        <TextField
          required
          labelClassName="labelClass"
          id="cleaningFee"
          label="Taxa de limpeza"
          placeholder="0,00"
          formik={formik}
          value={formik?.values.cleaningFee}
          mask="money"
          disabled={isLoadingProperty}
        />
      </div>
      <div className="two-columns">
        <SimpleSelect
          required
          id="propertyType"
          label="Tipo de propriedade"
          className="selector"
          options={propertyTypeOptions}
          labelClassName="labelClass"
          formik={formik}
          firstValue={formik?.values.propertyType}
          disabled={isLoadingProperty}
        />
        <SimpleSelect
          // required
          disabled
          id="status"
          label="Status da propriedade"
          className="selector"
          options={propertyStatusOptions}
          labelClassName="labelClass"
          formik={formik}
          firstValue={formik?.values.status}
        />
      </div>
      <div className="one-column">
        <TextField
          labelClassName="labelClass"
          id="boundAmount"
          label="Caução"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.boundAmount}
          mask="money"
          disabled={isLoadingProperty}
        />
      </div>
      <div className={formik?.values.status === 'Inactive' ? 'three-columns nowrap' : 'two-columns'}>
        {formik?.values.status === 'Inactive' && (
        <DatePicker
          required
          viewsCustom={['day', 'month', 'year']}
          label="Data encerramento de contrato"
          id="contractEndDate"
          minDate={new Date('2000-01-02')}
          labelClassName="labelClass"
          formik={formik}
        />
        )}
        {formik?.values?.changeContractStartDate ? (
          <DatePicker
            required
            viewsCustom={['day', 'month', 'year']}
            label="Data início de contrato"
            id="newContractStartDate"
            minDate={moment().startOf('month').add(1, 'days').toDate()}
            labelClassName="labelClass"
            formik={formik}
          />
        ) : (
          <DatePicker
            required
            viewsCustom={['day', 'month', 'year']}
            label="Data início de contrato"
            id="contractStartDate"
            minDate={new Date('2000-01-02')}
            labelClassName="labelClass"
            formik={formik}
            disableDate
          />
        )}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
        >
          <Checkbox
            labelName="Está em processo de churn?"
            checked={isChurnChecked}
            onClick={handleCheckChurnCheckbox}
          />
        </div>
      </div>
      <div className="two-columns">
        {isEditingForm && (
        <div>
          <Label>
            Trocar data de início de contrato?
          </Label>
          <div style={{ marginBottom: '4px' }} />
          <ControlledSwitchButton
            checked={formik?.values?.changeContractStartDate}
            handleChange={() => {
              formik?.setFieldValue('changeContractStartDate', !formik?.values?.changeContractStartDate);
            }}
          />
        </div>
        )}
      </div>
      <div className="three-columns">
        <TextField
          required
          labelClassName="labelClass"
          id="guestCapacity"
          label="Cap.de hóspedes"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.guestCapacity}
          type="number"
          disabled={isLoadingProperty}
        />
        <TextField
          required
          labelClassName="labelClass"
          id="singleBedQuantity"
          label="Qtd. camas solteiro"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.singleBedQuantity}
          type="number"
          disabled={isLoadingProperty}
        />
        <TextField
          required
          labelClassName="labelClass"
          id="doubleBedQuantity"
          label="Qtd. camas de casal"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.doubleBedQuantity}
          type="number"
          disabled={isLoadingProperty}
        />
      </div>
      <div className="three-columns">
        <TextField
          required
          labelClassName="labelClass"
          id="kingBedQuantity"
          label="Qtd. camas king"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.kingBedQuantity}
          type="number"
          disabled={isLoadingProperty}
        />
        <TextField
          required
          labelClassName="labelClass"
          id="queenBedQuantity"
          label="Qtd. camas queen"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.queenBedQuantity}
          type="number"
          disabled={isLoadingProperty}
        />
        <TextField
          required
          labelClassName="labelClass"
          id="singleSofaBedQuantity"
          label="Qtd. sofá camas solteiro"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.singleSofaBedQuantity}
          type="number"
          disabled={isLoadingProperty}
        />
      </div>
      <div className="three-columns">
        <TextField
          required
          labelClassName="labelClass"
          id="doubleSofaBedQuantity"
          label="Qtd. sofá camas casal"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.doubleSofaBedQuantity}
          type="number"
          disabled={isLoadingProperty}
        />
        <TextField
          required
          labelClassName="labelClass"
          id="pillowQuantity"
          label="Qtd. travesseiros"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.pillowQuantity}
          type="number"
          disabled={isLoadingProperty}
        />
        <TextField
          required
          labelClassName="labelClass"
          id="bedroomQuantity"
          label="Qtd. quartos"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.bedroomQuantity}
          type="number"
          disabled={isLoadingProperty}
        />
      </div>
      <div className="three-columns">
        <TextField
          required
          labelClassName="labelClass"
          id="bathroomQuantity"
          label="Qtd.banheiros"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.bathroomQuantity}
          type="number"
          disabled={isLoadingProperty}
        />
        <TextField
          required
          labelClassName="labelClass"
          id="lavatoryQuantity"
          label="Lavabos"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.lavatoryQuantity}
          type="number"
          disabled={isLoadingProperty}
        />
        <TextField
          required
          labelClassName="labelClass"
          id="extraDayPreparation"
          label="Tempo de preparo"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.extraDayPreparation}
          type="number"
          disabled={isLoadingProperty}
        />
      </div>
    </Container>
  );
};

FormAddProperty.defaultProps = {
  formik: undefined,
};

export default FormAddProperty;
