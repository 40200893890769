import styled from 'styled-components';
import { ContainerCheckbox } from '../../styles';
import InputCheckBox from '../CheckBox';
import CheckBoxList from '../CheckBoxList';
import { Text } from '../../../../components/Text';
import { comodidadesAddress } from '../../mock';

const ContainerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`;

const Column = styled.div<{ width?: string }>`
  flex: 1 1 ${({ width }) => (width ? `${width}` : '25%')};
  max-width: ${({ width }) => (width ? `${width}` : '25%')};
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex: 1 1 100%;
    max-width: 100%;
  }
`;

const WrapperCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 16px;
`;

const AddressAmenities = ({ checked, labelName, onChange }: any) => (
  <>
    <div style={{ marginBottom: '32px' }}>
      <CheckBoxList options={comodidadesAddress} />
    </div>

    <ContainerWrap>
      <Column>
        <WrapperCheckbox>
          <Text $color="#000" $fontWeight="500" $fontSize="16">
            Check-in/check-out expressos
          </Text>
          <ContainerCheckbox>
            <InputCheckBox checked labelName="Sim" />
            <InputCheckBox checked={false} labelName="Não" />
          </ContainerCheckbox>
        </WrapperCheckbox>
      </Column>
      <Column>
        <WrapperCheckbox>
          <Text $color="#000" $fontWeight="500" $fontSize="16">
            Recepção 24 Horas
          </Text>
          <ContainerCheckbox>
            <InputCheckBox checked={false} labelName="Sim" />
            <InputCheckBox checked={false} labelName="Não" />
          </ContainerCheckbox>
        </WrapperCheckbox>
      </Column>
      <Column>
        <WrapperCheckbox>
          <Text $color="#000" $fontWeight="500" $fontSize="16">
            Estacionamento
          </Text>
          <ContainerCheckbox>
            <InputCheckBox checked={false} labelName="Sim" />
            <InputCheckBox checked={false} labelName="Não" />
          </ContainerCheckbox>
        </WrapperCheckbox>
      </Column>
      <Column>
        <WrapperCheckbox>
          <Text $color="#000" $fontWeight="500" $fontSize="16">
            Tipo de Estacionamento
          </Text>
          <ContainerCheckbox>
            <InputCheckBox checked={false} labelName="Local" />
            <InputCheckBox checked={false} labelName="Proxímo" />
          </ContainerCheckbox>
        </WrapperCheckbox>
      </Column>
    </ContainerWrap>
  </>
);
export default AddressAmenities;
