/* eslint-disable max-len */
import {
  useState, useEffect, useRef, useCallback,
} from 'react';
import Timeline, { DateHeader, SidebarHeader, TimelineHeaders } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';

import { MultiSelect } from 'react-multi-select-component';

import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@mui/icons-material';

import { useFormik } from 'formik';

import {
  ButtonGroup,
  Chip,
  FilterContainer,
  Header,
  Label,
  MultiSelectWrapper,
  MultiSelectContent,
  TimelineContainer,
} from './styles';

import {
  getReservationDataById,
  getReservationDetailsById,
  getReservationPaymentsId,
} from '../../services/Reservation/request';

import { processReservationData } from '../../utils/ReservationMap';
import { getProperties } from '../../services/Expenses/request';
import { useReservationDetails } from '../../context/ReservationDetailsContext';
import { useLoader } from '../../context/LoaderContext';
import { useToastErrorMessage } from '../../utils/Messages';
import { useUser } from '../../context/UserContext';

import ModalFinance from './ModalFinance';
import ModalReservationBlocked from '../OwnerPage/Modal/ModalReservation/Blocked';
import DatePickerRange from '../DatePickerRange';
import ItemRenderer from './ItemRenderer';
import GroupRenderer from './GroupRenderer';
import LateralModal, { LateralModalHandle } from './Modal/LateralModal';
import ControlReservation from './ControlReservation';
import InformationReservation from './InformationReservation';
import ModalGuest from './ModalGuest';
import { Text } from '../Text';
import HeaderRenderer from './HeaderRenderer';
import { useScreenResize } from '../../hooks/useSceenResize/useScreenResize';

interface Default {
  id: number
  title: string
  rightTitle: string
}

interface Options {
  label: string
  value: number | string,
}

interface Guest {
  id: number;
  first_name: string;
  last_name: string;
}

interface OTA {
  id: number;
  name: string;
}

interface Property {
  code: string;
  extra_day_preparation: number;
  id: number;
}

interface Reservation {
  blocking_reason?: string;
  check_in_date?: string;
  check_out_date?: string;
  daily_net_value?: string;
  end_time?: number;
  group?: number;
  guest?: Guest;
  icon?: string;
  id?: number;
  is_block_for_pricing?: boolean;
  is_blocking?: boolean;
  is_last_minute?: boolean;
  ota?: OTA;
  price?: string;
  property?: Property;
  start_time?: number;
  status?: string;
  title?: string;
}

const MyTimeline = () => {
  const { isMobile, widthWindow } = useScreenResize();
  const { userInformation } = useUser();
  const roles = userInformation?.roles || [];
  const isAdmin = roles.includes('Admin') || roles.includes('SeazoneAdministrative');
  const { setLoad } = useLoader();
  const toastErrorRequest = useToastErrorMessage();
  const {
    setReservationDetails,
    setGroupSelect,
    items,
    setItems,
    reservationDetails,
  } = useReservationDetails();

  const [visibleTimeStart, setVisibleTimeStart] = useState(moment().startOf('day').subtract(2, 'days').valueOf());
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(moment().startOf('day').add(12, 'days').valueOf());
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedGroupsReservationStatus, setSelectedGroupsReservationStatus] = useState([]);
  const [modal, setModal] = useState(false);
  const [controlReservation, setControlReservation] = useState(false);
  const [modalBlockingByPreparationTime, setModalBlockingByPreparationTime] = useState<{
    open: boolean,
    infos: any,
  }>({
    open: true,
    infos: null,
  });

  const [groupsData, setGroupsData] = useState<Default[]>([]);
  const [options, setOptions] = useState<Options[]>([]);

  const STATUS_CONFIRMED = 'Active,In progress,Concluded'; // neste caso, o front busca por reservas com status Active, In progress e Concluded, e filtra aquelas em que "is_blocking" = False
  const STATUS_BLOCKING = 'Concluded,In progress,Active'; // neste caso, o front busca por reservas com status Active, In progress e Concluded, e filtra aquelas em que "is_blocking" = True
  const STATUS_CANCELED = 'Canceled';

  const optionsReservationStatus: Options[] = isAdmin ? [
    {
      label: 'Confirmadas',
      value: STATUS_CONFIRMED,
    },
    {
      label: 'Canceladas',
      value: STATUS_CANCELED,
    },
    {
      label: 'Bloqueios',
      value: STATUS_BLOCKING,
    },
  ] : [ // Host user
    {
      label: 'Confirmadas',
      value: STATUS_CONFIRMED,
    },
    {
      label: 'Bloqueios',
      value: STATUS_BLOCKING,
    },
  ];

  const modalRef = useRef<LateralModalHandle>(null);

  const formik = useFormik({
    initialValues: {
      startDate: moment().startOf('day').subtract(2, 'days'),
      endDate: moment().startOf('day').add(11, 'days'),
    },
    onSubmit: async () => {
    },
  });

  const keys = {
    groupIdKey: 'id',
    groupTitleKey: 'title',
    groupRightTitleKey: 'rightTitle',
    itemIdKey: 'id',
    itemTitleKey: 'title',
    itemDivTitleKey: 'title',
    itemGroupKey: 'group',
    itemTimeStartKey: 'start_time',
    itemTimeEndKey: 'end_time',
    groupLabelKey: 'title',
  };

  const handleNext = () => {
    const newVisibleTimeStart = moment(visibleTimeStart).add(14, 'days').valueOf();
    const newVisibleTimeEnd = moment(visibleTimeEnd).add(14, 'days').valueOf();

    const newVisibleTimeEndField = moment(visibleTimeEnd).add(13, 'days').valueOf();

    formik?.setFieldValue('startDate', newVisibleTimeStart);
    formik?.setFieldValue('endDate', newVisibleTimeEndField);

    setVisibleTimeStart(newVisibleTimeStart);
    setVisibleTimeEnd(newVisibleTimeEnd);
  };

  const handlePrevious = () => {
    const newVisibleTimeStart = moment(visibleTimeStart).subtract(14, 'days').valueOf();
    const newVisibleTimeEnd = moment(newVisibleTimeStart).add(14, 'days').valueOf();

    const newVisibleTimeEndField = moment(newVisibleTimeStart).add(13, 'days').valueOf();

    formik?.setFieldValue('startDate', newVisibleTimeStart);
    formik?.setFieldValue('endDate', newVisibleTimeEndField);

    setVisibleTimeStart(newVisibleTimeStart);
    setVisibleTimeEnd(newVisibleTimeEnd);
  };

  const getReservationsWithStatus = () => {
    if (isAdmin) return 'Concluded,Active,In progress,Pre-booking,Not confirmed,No-Show,Canceled';
    return 'Concluded,Active,In progress,Pre-booking,Not confirmed,No-Show';
  };

  const formatedStatus = (filteredByStatus?: string) => {
    if (isAdmin) return filteredByStatus;

    const result = `${filteredByStatus}`
      .replace('Canceled', '')
      .split(',')
      .map((status) => status.trim())
      .filter((status) => status)
      .join(',');

    return result;
  };

  const statusIsConfirmed = (status: string) => status === STATUS_CONFIRMED;

  const statusIsBlocking = (status: string) => status === STATUS_BLOCKING;

  const getReservation = async (propertyId: string, filteredByStatus?: string) => {
    setLoad(true);

    const status = formatedStatus(filteredByStatus) || getReservationsWithStatus();

    const results = await getReservationDataById(
      moment(visibleTimeStart).format('YYYY-MM-DD'),
      moment(visibleTimeEnd).subtract(1, 'days').format('YYYY-MM-DD'),
      propertyId,
      status,
    );

    let response = [...results];
    if (statusIsBlocking(status)) {
      response = response.filter((item: any) => item.is_blocking);
    } else if (statusIsConfirmed(status)) {
      response = response.filter((item: any) => !item.is_blocking);
    }

    const transformedItems: Reservation[] = [];
    const transformedGroups: Default[] = [];

    (response || []).forEach((item: any) => {
      transformedItems.push({
        ...item,
        id: item.id,
        group: item.property.id,
        title: `${item.guest.first_name} ${item.guest.last_name}`,
        start_time: moment(item.check_in_date).add(12, 'hours').valueOf(),
        end_time: moment(item.check_out_date).add(12, 'hours').valueOf(),
        icon: item.ota.name,
        price: item.daily_net_value,
        status: item.status,
        is_blocking: item.is_blocking,
        blocking_reason: item.blocking_reason,
        is_last_minute: item.is_last_minute,
        is_block_for_pricing: item.is_block_for_pricing,
      });

      if (!transformedGroups.some((group: any) => group.id === item.property.id)) {
        const name: any = options.find((option: any) => option.value === item.property.id);
        if (!name) return;
        transformedGroups.push({
          id: item.property.id,
          title: name?.label ? name?.label?.split('-')[0] : '',
          rightTitle: name?.label ? name?.label?.split('-')[1] : '',
        });
      }
    });

    const ids = propertyId.split(',');
    if (transformedGroups.length !== propertyId.split(',').length) {
      ids.forEach((idGroup: any) => {
        if (transformedGroups.some((group: any) => group.id === Number(idGroup))) return;

        const name: any = options.find((option: any) => option.value === Number(idGroup));

        transformedGroups.push({
          id: name?.value || '',
          title: name?.label ? name?.label.split('-')[0] : '',
          rightTitle: name?.label ? name?.label.split('-')[1] : '',
        });
      });
    }
    setItems(transformedItems.sort(
      (a: Reservation, b: Reservation) => (a?.property?.code || '').localeCompare(
        b?.property?.code || '')));
    setGroupsData(transformedGroups.sort(
      (a: Default, b: Default) => a.title.localeCompare(b.title)));
    setLoad(false);
  };

  const getPropertiesIdsAndReservationStatus = (propertiesGroups: any[], statusGroups: any[]) => {
    const valuesPropertiesIds = propertiesGroups.map((item: any) => item.value);
    const ids = valuesPropertiesIds.length > 0 ? valuesPropertiesIds.join(',') : '';

    const valuesReservationStatus = statusGroups.map((item: any) => item.value);
    const status = valuesReservationStatus.length > 0 ? valuesReservationStatus.join(',') : '';

    return { ids, status };
  };

  const onSelectGroup = async (selected: any) => {
    if (selected.length === 0) {
      setSelectedGroups([]);
      setItems([]);
      setGroupsData([]);
      return;
    }

    const { ids, status } = getPropertiesIdsAndReservationStatus(selected, selectedGroupsReservationStatus);
    await getReservation(ids, status);
    setSelectedGroups(selected);
  };

  const onSelectGroupReservationStatus = async (selected: any) => {
    const { ids, status } = getPropertiesIdsAndReservationStatus(selectedGroups, selected);
    setSelectedGroupsReservationStatus(selected);

    if (selectedGroups.length !== 0) {
      await getReservation(ids, status);
    }
  };

  const handleUpdateStatus = (id: number, status: string) => {
    const updatedItems = items.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          status,
        };
      }
      return item;
    });

    setItems(updatedItems);
  };

  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (rootElement) {
      rootElement.style.overflow = 'hidden';
    }
    const specificElement = document.querySelector('.container-auto');
    specificElement?.classList?.toggle('overflow-auto');

    if (!isMobile) return;

    const elementos = document.querySelectorAll('.rct-scroll');

    elementos.forEach((elemento: any) => {
      const wrapper = document.createElement('div');
      wrapper.style.position = 'relative';
      wrapper.style.display = 'inline-block';
      wrapper.style.width = '100%';
      wrapper.style.height = '100%';
      wrapper.style.overflowX = 'auto';
      wrapper.style.overflowY = 'hidden';
      elemento.parentNode.insertBefore(wrapper, elemento);
      wrapper.appendChild(elemento);

      wrapper.addEventListener('touchmove', (event) => {
        event.stopPropagation();
      }, { passive: false });
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoad(true);
      const status = 'Active,Onboarding';
      const response = await getProperties(status);
      const transformedGroups = response
        .map((item) => ({
          value: item.id,
          label: `${item?.code} - ${item?.owners?.[0]?.user?.first_name} ${item?.owners?.[0]?.user.last_name}`,
        }));

      setOptions(transformedGroups);
      setLoad(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedGroups.length === 0) return;

    const fetchData = async () => {
      setItems([]);
      const { ids, status } = getPropertiesIdsAndReservationStatus(selectedGroups, selectedGroupsReservationStatus);
      await getReservation(ids, status);
    };

    fetchData();
  }, [visibleTimeStart]);

  const getInformationReservation = async (reservationId: number) => {
    try {
      setLoad(true);

      const [response, reserva] = await Promise.all([
        getReservationDetailsById(reservationId),
        items.find((item: any) => item.id === reservationId),
      ]);

      const data = await processReservationData(response, reserva);

      setReservationDetails({ ...data });
      setModal(true);
    } catch (error) {
      if (error instanceof Error) {
        toastErrorRequest(error);
      }
    } finally {
      setLoad(false);
    }
  };

  const getBlockingByPreparationTime = (item: any) => {
    setModalBlockingByPreparationTime({
      open: true,
      infos: item,
    });
  };

  const handleChangeDate = () => {
    const date1: any = moment(formik?.values.startDate);
    const date2 = moment(formik?.values.endDate);
    const diffDays = date2.diff(date1, 'days');
    if (diffDays > 14 || diffDays < 14) {
      const newDate2 = date1.clone().add(13, 'days');
      const newDate3 = date1.clone().add(14, 'days');
      formik?.setFieldValue('endDate', newDate2);
      setVisibleTimeStart(date1.valueOf());
      setVisibleTimeEnd(newDate3);
    } else {
      setVisibleTimeStart(date1.valueOf());
      setVisibleTimeEnd(date2.valueOf());
    }
  };

  const handleGetFinancial = async (index: number) => {
    if (index !== 2) return;

    setLoad(true);
    const response = await getReservationPaymentsId(reservationDetails.id);

    setReservationDetails((prev: any) => ({ ...prev, payment: response }));
    setLoad(false);
  };

  const MemoizedItemRenderer = useCallback(
    ({ item, getItemProps }: any) => (
      <ItemRenderer
        item={item}
        getItemProps={getItemProps}
        onClick={() => (item?.blocking_reason === 'Preparation'
          ? getBlockingByPreparationTime(item) : getInformationReservation(item.id))}
      />
    ),
    [items]);

  const MemoizedGroupRenderer = useCallback(
    ({ group }) => <GroupRenderer group={group} />, [groupsData]);

  return (
    <TimelineContainer className="my-timeline-container">
      <Header>
        <Text $color="#000" $fontSize="20">
          Multicalendar
        </Text>
      </Header>

      <FilterContainer widthWindow={widthWindow}>
        <MultiSelectWrapper>
          <MultiSelectContent widthWindow={widthWindow / 2}>
            <Label>Cod. Apart.</Label>
            <div style={{ padding: '4px 0' }} />
            <MultiSelect
              options={options}
              value={selectedGroups}
              onChange={onSelectGroup}
              isLoading={options.length <= 0}
              labelledBy="Select"
              closeOnChangedValue={false}
              overrideStrings={{
                allItemsAreSelected: 'Todos apartamentos selecionados',
                clearSearch: 'Limpar Pesquisa',
                clearSelected: 'Limpar seleção',
                noOptions: 'Sem opções',
                search: 'Pesquisar',
                selectAll: 'Selecionar todos',
                selectAllFiltered: 'Selecionar todos (Filtrados)',
                selectSomeItems: 'Selecionar...',
                create: 'Criar',
              }}
            />
          </MultiSelectContent>

          <MultiSelectContent widthWindow={widthWindow / 2}>
            <Label>Tipo de Reservas</Label>
            <div style={{ padding: '4px 0' }} />
            <MultiSelect
              options={optionsReservationStatus}
              value={selectedGroupsReservationStatus}
              onChange={onSelectGroupReservationStatus}
              isLoading={optionsReservationStatus.length <= 0}
              labelledBy="Select"
              closeOnChangedValue={false}
              overrideStrings={{
                allItemsAreSelected: 'Todos status selecionados',
                clearSearch: 'Limpar Pesquisa',
                clearSelected: 'Limpar seleção',
                noOptions: 'Sem opções',
                search: 'Pesquisar',
                selectAll: 'Selecionar todos',
                selectAllFiltered: 'Selecionar todos (Filtrados)',
                selectSomeItems: 'Selecionar...',
                create: 'Criar',
              }}
            />
          </MultiSelectContent>
        </MultiSelectWrapper>

        <div style={{ marginBottom: '3px' }}>
          <DatePickerRange
            hasInitialDates
            formik={formik}
            id1="startDate"
            id2="endDate"
            minDate={new Date('2000-01-02')}
            onChange={handleChangeDate}
            hiddenIcon
            showingLabel={false}
            labelTextField1="Check-in"
            labelTextField2="Check-out"
          />
        </div>

        <ButtonGroup>
          <button type="button" onClick={handlePrevious}>
            <ArrowBackIosIcon />
            Retroceder 14 dias
          </button>
          <button type="button" onClick={handleNext}>
            Avançar 14 dias
            <ArrowForwardIosIcon />
          </button>
        </ButtonGroup>
      </FilterContainer>

      <div>
        <Timeline
          groups={groupsData}
          items={items}
          keys={keys}
          canMove={false}
          itemHeightRatio={0.75}
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
          sidebarWidth={180}
          lineHeight={60}
          itemRenderer={MemoizedItemRenderer}
          groupRenderer={MemoizedGroupRenderer}
          canChangeGroup
          stackItems
          buffer={1}
          onCanvasClick={(groupId, time) => {
            setGroupSelect({ propertyId: groupId, date: time });
            setControlReservation(true);
          }}
        >
          <TimelineHeaders>
            {items.length === 0 && (
            <SidebarHeader>
              {({ getRootProps }) => (
                <Chip {...getRootProps()}>
                  <Text $color="000" $fontSize="14">
                    Selecione apartamento
                  </Text>
                </Chip>
              )}
            </SidebarHeader>
            )}
            <DateHeader unit="primaryHeader" labelFormat="MMM YYYY" style={{ display: 'none' }} />
            <DateHeader unit="day" intervalRenderer={HeaderRenderer} />
          </TimelineHeaders>
        </Timeline>
      </div>

      {controlReservation
        && (
        <ControlReservation
          onClose={() => setControlReservation(false)}
        />
        )}

      {modal && (
        <LateralModal
          title="Dados da Reserva"
          ref={modalRef}
          hasTab={!reservationDetails?.is_blocking}
          onClose={() => setModal(false)}
          onChangeTab={handleGetFinancial}
        >
          <InformationReservation
            openFinance={() => modalRef?.current?.setState(2)}
            onUpdateStatus={handleUpdateStatus}
            onClose={() => setModal(false)}
          />
          <ModalGuest />
          <ModalFinance />
        </LateralModal>
      )}

      {modalBlockingByPreparationTime.open && modalBlockingByPreparationTime?.infos && (
        <ModalReservationBlocked
          id={modalBlockingByPreparationTime.infos?.id || 1}
          open={modalBlockingByPreparationTime.open}
          onClose={() => setModalBlockingByPreparationTime({
            open: false,
            infos: null,
          })}
          guestName={''}
          petIndicator={false}
          adultGuests={0}
          childrenGuests={0}
          blocked
          startDate={modalBlockingByPreparationTime.infos?.check_in_date}
          endDate={modalBlockingByPreparationTime.infos?.check_out_date}
          reason={'Preparation'}
          ota={{
            id: 0,
            name: 'Blocking',
            initials: 'BLK',
            img_url: '',
          }}
          totalPrice={0}
          notes={'0'}
          reservation={{} as any}
          dailyNetValue={0}
        />
      )}
    </TimelineContainer>
  );
};

export default MyTimeline;
