import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ReportBugModal } from '../../components/ReportBugModal/ReportBugModal';
import BottomHeader from '../../components/Sidebar/BottomHeader';
import HeaderMobile from '../../components/Sidebar/HeaderMobile';
import MenuLateral from '../../components/Sidebar/MenuLateral';
import { useRequestSupportModal } from '../../hooks/useRequestModal';
import { Container } from './styles';

const CUSTOM_STYLE_PAGES = ['/reembolso'];

const DefaultLayout = () => {
  const location = useLocation();
  const { isOpenedReportErrorModal, handleCloseReportBugModal } =
    useRequestSupportModal();

  const [openMenuLateral, setOpenMenuLateral] = useState(false);

  const isCustomStyle = CUSTOM_STYLE_PAGES.includes(location.pathname);

  return (
    <div>
      <HeaderMobile />
      <MenuLateral
        setOpenMenu={setOpenMenuLateral}
        openMenu={openMenuLateral}
      />
      <Container isCustomStyle={isCustomStyle} className="container-auto">
        <Outlet />
        {isOpenedReportErrorModal && (
          <ReportBugModal
            isModalOpen={isOpenedReportErrorModal}
            handleCloseModal={handleCloseReportBugModal}
          />
        )}
      </Container>
      <BottomHeader />
    </div>
  );
};

export default DefaultLayout;
