export const comodidades = [
  'Academia (privativa)',
  'Acessível por Elevador',
  'Acessível por Escadas Apenas',
  'Acesso à Praia',
  'Acesso ao Lago',
  'Acesso ao Resort',
  'Acesso Livre de Degraus em Casa',
  'Acomodação Térrea',
  'Adaptador',
  'Água Quente',
  'Almofada de Penas',
  'Almofada Hipoalergénica',
  'Almofada sem Penas',
  'Apartamento Privado num Edifício de Apartamentos',
  'Aquecimento Central',
  'Ar Condicionado',
  'Ar condicionado controlado individualmente no quarto',
  'Ar Condicionado Individual para o Quarto de Hóspedes',
  'Arara para Roupas',
  'Área de estar com sofá/cadeira',
  'Área de Refeições Exterior',
  'Armários no quarto',
  'Artigos de Praia',
  'Assentos na Sala de Jantar',
  'Banheira',
  'Banheira Adaptada',
  'Banheira de Hidromassagem',
  'Banheira e Hidromassagem separadas',
  'Banheira grande',
  'Banheira ou chuveiro separados',
  'Banheira para Bebê',
  'Banheiro Compartilhado',
  'Banheiro em mármore',
  'Banheiro sem chuveiro',
  'Barras de Apoio no Chuveiro',
  'Berço',
  'Berço Portátil',
  'Biblioteca de Livros/DVDs/Música para Crianças',
  'Bicicleta',
  'Bicicleta Infantil',
  'Bidê',
  'Blackout nas Cortinas',
  'Cabides',
  'Cadeira Alta para Crianças',
  'Cadeira de Ducha',
  'Cadeira fornecida com a mesa',
  'Café',
  'Café da Manhã Incluído',
  'Caiaque',
  'Cama Dobrável',
  'Camas Extra-longas (> 2 metros)',
  'Canais a Cabo',
  'Canais Pay-per-view',
  'Capa para Piscina',
  'Carregador de VE',
  'Cartões Eletrónicos',
  'Casa de Banho Adicional',
  'Casa de Banho com Barras de Apoio',
  'Chaleira Elétrica',
  'Chaves Eletrónicas',
  'Chinelos',
  'Chocolate/Bolachas',
  'Churrasqueira',
  'Chuveiro Acessível',
  'Chuveiro Externo',
  'Cobertores e travesseiros extras',
  'Cobertores Elétricos',
  'Cofre',
  'Cofre grande o suficiente para acomodar um laptop',
  'Cofres',
  'Comodidades para hóspedes com mobilidade reduzida',
  'Compactador de Lixo',
  'Computador',
  'Condicionador',
  'Console de Jogos - PS4',
  'Console de Jogos - Wii U',
  'Console de Jogos - Xbox One',
  'Copos de Vinho',
  'Cordão de Emergência na Casa de Banho',
  'Cortina Privativa',
  'Cozinha Compartilhada',
  'Cozinha Completa',
  'Cozinha/Cozinha Compacta',
  'Depósito de Bagagem Permitido',
  'Desinfetante para as Mãos',
  'Detector de Fumaça',
  'Detector de Monóxido de Carbono',
  'Ducha',
  'Ducha Adaptada',
  'Ducha sem Degraus',
  'Elevador',
  'Em frente ao rio',
  'Entrada Privativa',
  'Equipamento de Exercício',
  'Escadas com Portão (segurança)',
  'Escova de Dentes',
  'Espaço pronto para uso de notebook',
  'Espreguiçadeiras',
  'Esqui In e Out',
  'Estação de ancoragem para iPod',
  'Estacionamento Gratuito',
  'Estacionamento na Rua',
  'Estacionamento Pago',
  'Estacionamento Pago no Local',
  'Estadias Prolongadas Permitidas',
  'Extintor de Incêndio',
  'Fax',
  'Ferro de Passar',
  'Filmes',
  'Fliperama',
  'Fogão',
  'Fogueira',
  'Folha de Assar',
  'Forno',
  'Freezer',
  'Frutas',
  'Garrafa de Água',
  'Gel de Banho',
  'Geladeira',
  'Grades de Janela',
  'Grades de Lareira',
  'Grades de Segurança para Bebês',
  'Grill para Churrasco',
  'Guarda-roupa ou Armário',
  'Guindaste de Teto',
  'Guindaste para Piscina',
  'Home Theater',
  'Interfone',
  'Internet',
  'Internet (via cabo)',
  'Itens Básicos de Banheiro',
  'Itens Básicos de Cozinha',
  'Jacuzzi',
  'Jardim ou Quintal',
  'Jogos de Tabuleiro/Quebra-Cabeças',
  'Kit de Primeiros Socorros',
  'Laptop',
  'Lareira Interna',
  'Laser Tag',
  'Lava-louças',
  'Lavanderia Próxima',
  'Lavatório mais Baixo',
  'Limpeza Antes do Check-out',
  'Liquidificador',
  'Lista de canais de filmes disponíveis',
  'Livre de Alergénicos',
  'Livros',
  'Lixeiras',
  'Local para Barco',
  'Louças e Talheres',
  'Luz de Leitura',
  'Máquina de Café',
  'Máquina de lavar e secar',
  'Máquina de Lavar Roupa',
  'Máquina de Pão',
  'Mesa com tomada elétrica',
  'Mesa de Jantar',
  'Mesa de Ping Pong',
  'Mesa de Sinuca',
  'Mesas e cadeiras',
  'Microondas',
  'Mini Golfe',
  'Mini-frigorífico',
  'Mobília de Exterior',
  'Monitor de Bebê',
  'Mosquiteiro',
  'Múltiplos armários',
  'Panela de Arroz',
  'Papel Higiênico',
  'Parede de Escalada',
  'Piano',
  'Pijama',
  'Piscina Aquecida Privada',
  'Piscina com Água Salgada',
  'Piscina com Borda Infinita',
  'Piscina com Lado Raso',
  'Piscina com Vista',
  'Piscina de Imersão',
  'Piscina na Cobertura',
  'Piscina não-aquecida Privada',
  'Piscina Privada',
  'Piscina Privada Climatizada',
  'Piso com Carpete',
  'Pista de Boliche',
  'Pista de Hóquei',
  'Pista de Skate',
  'Playground',
  'Porta Ampla em Casa',
  'Porteiro',
  'Prensa para Calças',
  'Produtos de Limpeza',
  'Proteção nos Cantos de Mesa',
  'Protetores de Tomada',
  'Purificadores de Ar Disponíveis',
  'Quarto à prova de som',
  'Quarto antialérgico',
  'Quarto de Vestir',
  'Quarto Temático',
  'Quartos para famílias',
  'Rádio',
  'Rádio via satélite',
  'Recomendações de Babá',
  'Rede de Descanso',
  'Reprodutor de Bluray',
  'Reprodutor de CD',
  'Reprodutor de DVD',
  'Reprodutor de videogame',
  'Roupão',
  'Roupas de cama',
  'Sala de Jogos',
  'Sauna',
  'Secador de Cabelo',
  'Secadora',
  'Serviço de Despertar/Relógio Despertador',
  'Serviço de filmes/vídeos gratuito',
  'Serviço de Streaming (como Netflix)',
  'Sistema de aquecimento/ar condicionado controlado pelo hóspede',
  'Sistema de Som',
  'Smartphone',
  'Sofá',
  'Sofá-Cama',
  'Tampões para os Ouvidos',
  'Telefone com duas linhas',
  'Telefone TDD/Textfone',
  'Telefones celulares',
  'Televisão de alta definição (HD) - 32 polegadas ou mais',
  'Televisão via satélite',
  'Terraço',
  'Tipo de roupa de cama de luxo',
  'Toalhas',
  'Toalhas para Piscina',
  'Toalhas/Lençóis com Sobretaxa',
  'Toca-discos',
  'Tomada Perto da Cama',
  'Torradeira',
  'Touca de Banho',
  'Trocador',
  'TV',
  'TV de tela larga',
  'Unidade Inteira Acessível de Cadeira de Rodas',
  'Utensílios de Jantar Infantil',
  'Utensílios para Churrasco',
  'Vaga de Estacionamento para Deficientes',
  'Varal para Secar Roupas',
  'Varanda',
  'Ventilador de teto',
  'Vídeo sob demanda',
  'Vídeo-game',
  'Vinho/Champanhe',
  'Vista para a Cidade',
  'Vista para a Montanha',
  'Vista para a Piscina',
  'Vista para a Praia',
  'Vista para o Jardim',
  'Vista para o Lago',
  'Vista para o Mar',
  'Vista para o Marco',
  'Vista para o Oceano',
  'Vista para o rio',
  'Vista para Pátio Interno',
  'Vista para Rua Tranquila',
  'WC para Convidados',
  'Wi-fi',
  'Wi-fi Portátil',
  'Xampu',
];

export const comodidadesAddress = [
  'Academia (área comum)',
  'Aluguel de bicicletas',
  'Aluguel de carros',
  'Ar condicionado',
  'Armários individuais',
  'Babá / Serviços para crianças',
  'Balcão de turismo',
  'Banho turco / Sauna a vapor',
  'Bar',
  'Beira-mar',
  'Biblioteca',
  'Business center',
  'Café da manhã incluso',
  'Caixa eletrônico na propriedade',
  'Campo de golfe',
  'Capela / Santuário',
  'Carregador elétrico veicular',
  'Casa noturna / DJ',
  'Cassino',
  'Churrasqueira / Área para piquenique',
  'Cofre',
  'Cozinha Compartilhada',
  'Elevador',
  'Engraxate',
  'Entrega de compras',
  'Entretenimento à noite',
  'Equitação',
  'Estacionamento com acessibilidade',
  'Estacionamento de rua (gratuito)',
  'Estacionamento de rua (pago)',
  'Estacionamento seguro (pago)',
  'Garagem',
  'Instalações para esportes aquáticos (na propriedade)',
  'Itens básicos de praia',
  'Jacuzzi (uso comum)',
  'Jardim',
  'Lavagem a seco',
  'Lavanderia',
  'Loja de presentes / Souvenirs',
  'Lojas (na propriedade)',
  'Máquina de Lavar (uso comum, paga ou grátis)',
  'Máquina de venda automática (lanches)',
  'Moveis externos',
  'Os funcionários seguem todos os protocolos de segurança definidos pelas autoridades locais',
  'Parquinho infantil',
  'Pátio ou Varanda',
  'Permite estadias acima de 28 noites',
  'Permitido deixar malas',
  'Pesca',
  'Piscina ao ar livre (Compartilhada)',
  'Piscina aquecida (Compartilhada)',
  'Piscina interna (Compartilhada)',
  'Praia privada',
  'Quadra de tênis',
  'Quartos para não fumantes',
  'Quintal',
  'Restaurante',
  'Salão / área de TV',
  'Salão de Jogos',
  'Sauna',
  'Secadora (uso comum, pago ou grátis)',
  'Serviço de câmbio',
  'Serviço de concierge',
  'Serviço de manobrista',
  'Serviço de quarto',
  'Serviço diário de limpeza',
  'Serviços de massagem',
  'Solário',
  'Terraço',
  'Transfer gratuito (aeroporto)',
  'Trilhas a pé',
  'Vista para a montanha',
  'Vista para a praia',
];
