import styled, { css } from 'styled-components';

const MAX_SIZE_MOBILE = 900; // 900px

export const Container = styled.form<{ isEditing?: boolean }>`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: ${({ isEditing }) => (isEditing ? 'repeat(4, 1fr)' : 'repeat(4, 1fr)')};
  padding: 16px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  
  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    min-height: 215px;
    border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
    margin: 12px 0;
    border-radius: 8px;
  }
`;

export const Content = styled.div<{ activeBorder?: boolean, isEditing?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;

  label {
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
  }

  .MuiOutlinedInput-root {
    border: ${({ isEditing }) => (isEditing ? 'none' : '1px solid #D9DCDF')};
    height: ${({ isEditing }) => (isEditing ? 'auto' : '40px')} !important;
  }
  .MuiOutlinedInput-input {
    padding: ${({ isEditing }) => (isEditing ? 0 : '0 8px')} !important;
    font-size: 14px !important;
    color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
    ::placeholder {
      font-size: 14px !important;
    }
    :disabled {
      color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
    }
  }
  fieldset {
    border: 0 !important;
  }

  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    label {
      font-family: Inter !important;
      font-size: 14px !important;
      font-weight: 600 !important;
      color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
    }
    
    :not(:last-child) {
      width: 90%;
    }
    
  ${({ activeBorder }) => activeBorder && css`
    border-right: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  `}
  }
`;

export const ContentButton = styled.div`
  gap: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    gap: 8px;
    justify-content: flex-end;
    align-items: flex-start;
  }
`;

export const Button = styled.button`
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    justify-content: flex-end;
    align-items: flex-end;
  }
`;
