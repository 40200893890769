import React from 'react';

import { WhatsAppFloatButton } from '../../WhatsApp';

import { BalanceCard, CategoryCard, IndicationsCard } from './Cards';

import {
  Content, Container, CardsContainer,
} from './style';
import { actualSize } from '../../../../hooks/useMobile/useMobile';

import Hello from './Hello';
import Lading from './Lading';

const OrderBy = {
  Desktop: (
    <>
      {/* <IndicationsCard /> */}
      <BalanceCard />
    </>
  ),
  Mobile: (
    <>
      <BalanceCard />
      {/* <IndicationsCard /> */}
    </>
  ),
};

const Panel = () => (
  <Container>
    <WhatsAppFloatButton />
    <Content>
      <Hello />
      <CardsContainer>
        <CategoryCard />
        {OrderBy[actualSize]}
      </CardsContainer>
    </Content>

    <Lading />
  </Container>
);

export default Panel;
