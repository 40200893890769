import { useEffect, useState } from 'react';
import {
  getProperPayStatus,
  putProperPayFinancialClosing,
} from '../../../services/FinancialClose/request';
import { useToast } from '../../../context/ToastContext';

import FormButton from '../../FormButton';

import {
  Divider,
  OpenFinancialUpdateModalBackdrop,
  OpenFinancialUpdateModalButtonsContainer,
  OpenFinancialUpdateModalCard,
  OpenFinancialUpdateModalCloseButton,
  OpenFinancialUpdateModalCloseIcon,
  OpenFinancialUpdateModalContentContainer,
  OpenFinancialUpdateModalHeader,
  OpenFinancialUpdateModalHeaderTitle,
} from './styles';
import { Spinner } from '../Grid/styles';
import { useLoader } from '../../../context/LoaderContext';

interface OpenFinancialUpdateModalProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
}

export const OpenFinancialUpdateModal = ({
  isModalOpen,
  handleCloseModal,
}: OpenFinancialUpdateModalProps) => {
  const { setLoad } = useLoader();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [status, setStatus] = useState('');

  async function handleTriggerUpdateFinancialClosing() {
    try {
      setIsLoading(true);
      const response = await putProperPayFinancialClosing();
      handleCloseModal();
      toast.success('Processo iniciado com sucesso, em alguns minutos os dados estará atualizado');
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        toast.alert('Falha ao iniciar o processo.');
      }
    }
  }

  async function handleGetProperPayStatus() {
    try {
      setIsLoadingStatus(true);
      const response = await getProperPayStatus();
      setStatus(response.status || '');
      setIsLoadingStatus(false);
    } catch (e) {
      setIsLoadingStatus(false);
      if (e instanceof Error) {
        toast.alert('Falha ao verificar  o status do fechamento.');
        handleCloseModal();
      }
    }
  }

  useEffect(() => {
    handleGetProperPayStatus();
  }, []);

  return (
    <OpenFinancialUpdateModalBackdrop
      isModalOpen={isModalOpen}
      onClick={handleCloseModal}
    >
      <OpenFinancialUpdateModalCard
        onClick={(e) => e.stopPropagation()}
      >
        <OpenFinancialUpdateModalHeader>
          <OpenFinancialUpdateModalHeaderTitle>
            Atualização de dados do financeiro
          </OpenFinancialUpdateModalHeaderTitle>
          <OpenFinancialUpdateModalCloseButton
            whileTap={{ scale: 0.8 }}
            onClick={handleCloseModal}
          >
            <OpenFinancialUpdateModalCloseIcon fontSize="large" />
          </OpenFinancialUpdateModalCloseButton>
        </OpenFinancialUpdateModalHeader>
        <Divider />
        <OpenFinancialUpdateModalContentContainer>
          {status === 'Running' && (
            <>
              Processo em andamento! Aguarde enquanto as tabelas estão sendo atualizadas.
              <Spinner isLoading />
            </>
          )}

          {isLoadingStatus && (
            <>
              Estamos verificando o status do fechamento.
              <Spinner isLoading />
            </>
          )}

        </OpenFinancialUpdateModalContentContainer>

        <OpenFinancialUpdateModalButtonsContainer>
          {status && status !== 'Running' && (
          <FormButton
            type="button"
            onClick={handleTriggerUpdateFinancialClosing}
            disable={isLoading}
          >
            Iniciar fechamento
          </FormButton>
          )}
        </OpenFinancialUpdateModalButtonsContainer>
      </OpenFinancialUpdateModalCard>
    </OpenFinancialUpdateModalBackdrop>
  );
};

export default OpenFinancialUpdateModal;
