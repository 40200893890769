import { MultiSelect } from 'react-multi-select-component';
import { useState } from 'react';
import { FormButton } from '../../components';
import Step from '../../components/Step';
import { useScreenResize } from '../../hooks/useSceenResize/useScreenResize';
import AccommodationAmenities from './components/AddressAmenities';
import {
  Container,
  ContainerFilter,
  ContainerInitial,
  ContainerStepper,
  Content,
  Header,
  Label,
  MultiSelectContent,
  Wrapper,
} from './styles';
import { Text } from '../../components/Text';
import AddressAmenities from './components/AccommodationAmenities';
import update from '../../assets/icons/generals/update.svg';
import imageContruction from '../../assets/icons/generals/construction.svg';
import Box from '../../components/Box';

interface Props {
  hasContruction: boolean;
}

const PropertyAmenities = ({ hasContruction }: Props) => {
  const { widthWindow } = useScreenResize();
  const [step, setStep] = useState(1);
  const [construction, setConstruction] = useState(hasContruction);
  return (
    <Container>
      <Header>
        <h1>Comodidades das Propriedades</h1>
      </Header>

      <ContainerFilter>
        <MultiSelectContent widthWindow={widthWindow / 2}>
          <Label>Selecionar Imóvel</Label>
          <div style={{ padding: '4px 0' }} />
          <MultiSelect
            options={[]}
            value={[{ label: '', value: '' }]}
            isLoading={false}
            labelledBy="Select"
            closeOnChangedValue={false}
            overrideStrings={{
              allItemsAreSelected: 'Todos imoveis selecionados',
              clearSearch: 'Limpar Pesquisa',
              clearSelected: 'Limpar seleção',
              noOptions: 'Sem opções',
              search: 'Pesquisar',
              selectAll: 'Selecionar todos',
              selectAllFiltered: 'Selecionar todos (Filtrados)',
              selectSomeItems: 'Selecionar...',
              create: 'Criar',
            }}
          />
        </MultiSelectContent>
        <div
          style={{
            display: 'flex',
            width: '128px',
          }}
        >
          <FormButton type="submit">Pesquisar</FormButton>
        </div>
      </ContainerFilter>

      <Content>
        {construction ? (
          <ContainerInitial>
            <img src={imageContruction} alt="construction" />
            <Text $color="#2557BF" $fontSize="24" $fontWeight="700">
              Tela em Construção
            </Text>
            <Text $color="#000" $fontSize="16" $fontWeight="500">
              Estamos trabalhando pra melhorar sistema.
            </Text>
          </ContainerInitial>
        ) : (
          <>
            <ContainerStepper>
              <Wrapper>
                <Text $color="#000" $fontSize="16" $fontWeight="bold">
                  PPP11
                </Text>
                <div
                  style={{
                    display: 'flex',
                    width: '128px',
                  }}
                >
                  <FormButton variant="outlined" type="submit">
                    Atualizar
                    <img style={{ marginLeft: '8px' }} src={update} alt="update" />
                  </FormButton>
                </div>
              </Wrapper>
              <Wrapper>
                <Text $color="#000" $fontSize="14">
                  Florianópolis - SC
                </Text>
                <Text $color="#000" $fontSize="14">
                  Atuzalizada 03/09/2024
                </Text>
              </Wrapper>
              <Step activeStep={step} />
            </ContainerStepper>
            {step === 1 ? <AddressAmenities /> : <AccommodationAmenities />}
            <Box alignItems="center" justifyContent="flex-end" border="none">
              <div
                style={{
                  display: 'flex',
                  width: '128px',
                  height: '58px',
                }}
              >
                <FormButton
                  type="submit"
                  variant={step === 1 ? undefined : 'outlined'}
                  onClick={() => setStep((prev) => (step === 1 ? prev + 1 : prev - 1))}
                >
                  {step === 1 ? 'Próximo' : 'Voltar'}
                </FormButton>
              </div>
            </Box>
          </>
        )}
      </Content>
    </Container>
  );
};

export default PropertyAmenities;
