import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import React, { useState } from 'react';
import styled from 'styled-components';

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
`;

const PageButton = styled.button<{ active?: boolean }>`
  padding: 10px 15px;
  margin: 0 5px;
  border: none;
  background-color: ${({ active }) => (active ? 'rgba(0, 122, 255, 0.2)' : '#fff')};
  color: rgba(97, 97, 97, 1);
  font-weight: 700;
  font-family: 'Quicksand', sans-serif;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
`;

const Ellipsis = styled.span`
  margin: 0 5px;
`;

const NavigationButton = styled(PageButton)`
  font-weight: bold;
`;

interface PaginationProps {
  totalPages: number;
  handleChangePage: Function;
}

const Pagination: React.FC<PaginationProps> = ({ totalPages, handleChangePage }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
    handleChangePage(page);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      handleChangePage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      handleChangePage(currentPage + 1);
    }
  };

  const getPaginationRange = () => {
    const range: (JSX.Element | number)[] = [];
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, currentPage + 1);

    range.push(
      <PageButton key={1} active={currentPage === 1} onClick={() => handlePageClick(1)}>
        1
      </PageButton>,
    );

    if (startPage > 3) {
      range.push(<Ellipsis key="ellipsis-start">...</Ellipsis>);
    }

    for (let i = startPage; i <= endPage; i += 1) {
      if (i > 1) {
        range.push(
          <PageButton key={i} active={i === currentPage} onClick={() => handlePageClick(i)}>
            {i}
          </PageButton>,
        );
      }
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) range.push(<Ellipsis key="ellipsis-end">...</Ellipsis>);
      range.push(
        <PageButton
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => handlePageClick(totalPages)}
        >
          {totalPages}
        </PageButton>,
      );
    }

    return range;
  };

  return (
    <PaginationContainer>
      <NavigationButton onClick={handlePrevious} disabled={currentPage === 1}>
        <ChevronLeft />
      </NavigationButton>
      {getPaginationRange()}
      <NavigationButton onClick={handleNext} disabled={currentPage === totalPages}>
        <ChevronRight />
      </NavigationButton>
    </PaginationContainer>
  );
};

export default Pagination;
