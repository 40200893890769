/* eslint-disable consistent-return */
import { useEffect } from 'react';

import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';

import {
  EnumType,
  IIndications,
  IIndicationsBase,
  IIndicationStatusEnum,
  IPartnersResume,
  IInvestmentIndication,
} from '../../../types';

import { IFormValues } from './types';
import { analyticsEvent } from '../../../utils/analytics';
import { currencyToNumber } from '../../../../../utils/InputMask/Number';

import { useUser } from '../../../../../context/UserContext';
import { useToast } from '../../../../../context/ToastContext';
import { useLoader } from '../../../../../context/LoaderContext';
import { usePartners } from '../../../../../context/Partners/Partner/PartnerContext';
import { usePartnerIndicate } from '../../../../../context/Partners/IndicateContext/IndicateContext';

import {
  IAllotmentIndicatePost,
  IPropertyIndicatePost,
  postAllotmentIndication,
  postAllotmentIndicationFiles,
  postInvestmentIndication,
  postPropertyIndication,
  postPropertyIndicationFiles,
} from '../../../../../services/Partner';

import { LandSchema, LocationSchema, SpotSchema } from './schemas';
import { initialValuesLandForm, initialValuesLocationForm, initialValuesSpotForm } from './schemas/utils';

import Content from './Content';

const fieldsChanged: string[] = [];

const Form = () => {
  const { userInformation } = useUser();
  const toast = useToast();
  const { type } = useParams();
  const { setLoad } = useLoader();
  const { questions, setQuestions } = usePartners();
  const {
    propertyImages,
    propertyDocuments,
    setPropertyImages,
    setPropertyDocuments,
  } = usePartnerIndicate();

  const {
    resume,
    setResume,
    setIndications,
    setSuccessScreen,
  } = usePartners();

  function dispatchAnalytcsMsg(props: { terrain?: string, spot?: string, location?: string }) {
    if (type === EnumType.terrain) {
      analyticsEvent({
        userInformation,
        name: props.terrain || '',
      });
    } else if (type === EnumType.location) {
      analyticsEvent({
        userInformation,
        name: props.location || '',
      });
    } else if (type === EnumType.spot) {
      analyticsEvent({
        userInformation,
        name: props.spot || '',
      });
    }
  }

  useEffect(() => {
    dispatchAnalytcsMsg({
      terrain: 'Abriu o formulário para indicar um terreno',
      location: 'Abriu o formulário para indicar uma locação',
      spot: 'Abriu o formulário para indicar um spot',
    });
  }, []);

  const resetStates = () => {
    setPropertyImages([]);
    setPropertyDocuments([]);
    setQuestions({
      doesThePropertyHaveFurniture: false,
      hasTheOwnerAlreadyReceivedTheEBook: false,
      IsIndicatedWithinSeazoneOperationArea: false,
      isTheOwnerAwareOfTheIndication: false,
      isThePropertyUnderConstruction: false,
    });
  };

  const values: Record<EnumType, any> = {
    terreno: initialValuesLandForm,
    locacao: initialValuesLocationForm,
    spot: initialValuesSpotForm,
  };

  const schema: Record<EnumType, any> = {
    terreno: LandSchema,
    locacao: LocationSchema,
    spot: SpotSchema,
  };

  const formik = useFormik<IFormValues>({
    initialValues: values[type as EnumType],
    validationSchema: schema[type as EnumType],
    onSubmit: async (data) => {
      if (resume?.partner?.id) {
        try {
          setLoad(true);

          type IFormatBaseData = Omit<IIndicationsBase, 'id' | 'created_at' | 'updated_at' | 'status_change_date'>;
          const formatBaseData: IFormatBaseData = {
            owner_name: data.name,
            comment: data?.comment,
            partner_id: resume?.partner.id,
            owner_phone_number: data.phone,
            status: IIndicationStatusEnum.progress,
          };

          const postIndication = async () => {
            if (type === EnumType.terrain) {
              const formatData: IAllotmentIndicatePost = {
                ...formatBaseData,
                allotment_city: data.city,
                allotment_state: data.state,
                allotment_neighborhood: data.neighborhood,
                allotment_street: `${data.address}`.split(',')?.[0]?.trim() || '',
                allotment_address_number: `${data.address}`.split(',')?.[1]?.trim() || 's/n',
                allotment_area: data.allotmentArea,
                allotment_dimension: `${data.allotmentWidth}m x ${data.allotmentLenght}m` !== 'm x m' ? `${data.allotmentWidth}m x ${data.allotmentLenght}m` : '',
                allotment_value: currencyToNumber(data.allotmentValue),
                geographical_coordinate: data.coordinates,
                document_link: data.documentLink,
                photo_link: data.photoLink,
                allow_barter: data.allowBarter,
              };

              const response = await postAllotmentIndication(formatData);

              if (response.id) {
                const files = propertyImages.concat(propertyDocuments);
                await postAllotmentIndicationFiles(response.id, files);
              }
              return response;
            }
            if (type === EnumType.location) {
              const formatData: IPropertyIndicatePost = {
                ...formatBaseData,
                property_city: data.city,
                property_state: data.state,
                property_type: data.propertyType,
                property_street: data.street,
                property_number: data.number,
                property_complement: data.complement,
                property_neighborhood: data.neighborhood,
                condominium_name: data.b2bCondominiumID,
                property_is_in_coverage_area: questions.IsIndicatedWithinSeazoneOperationArea,
                property_under_construction: questions.isThePropertyUnderConstruction,
                property_has_furniture: questions.doesThePropertyHaveFurniture,
                owner_is_aware_of_indication: questions.isTheOwnerAwareOfTheIndication,
                owner_received_the_ebook: questions.hasTheOwnerAlreadyReceivedTheEBook,
                owner_email: '',
              };

              const response = await postPropertyIndication(formatData);

              if (response.id) {
                await postPropertyIndicationFiles(response.id, propertyImages);
              }
              return response;
            }

            if (type === EnumType.spot) {
              const formatData: IInvestmentIndication = {
                status: IIndicationStatusEnum.progress,
                partner_id: resume.partner.id,
                enterprise_id: data.enterprise,
                enterprise_unit_id: data.unit,
                investor_name: data.name,
                investor_email: data.email || '',
                investor_phone: data.phone,
                comment: data.comment,
              };

              return postInvestmentIndication(formatData);
            }
          };

          const response = await postIndication();

          setResume((prev: IPartnersResume) => ({
            ...prev,
            indications_in_progress: prev.indications_in_progress + 1,
          }));

          setIndications((prev: IIndications) => ({
            ...prev,
            allotments: type === EnumType.terrain
              ? [
                {
                  ...response,
                  type: EnumType.terrain,
                  status: 'In_Progress',
                },
                ...prev.allotments,
              ]
              : prev.allotments,

            propretys: type === EnumType.location
              ? [
                {
                  ...response,
                  status: 'In_Progress',
                  type: EnumType.location,
                },
                ...prev.propretys,
              ]
              : prev.propretys,

            spots: type === EnumType.spot
              ? [
                {
                  ...response,
                  status: 'In_Progress',
                  type: EnumType.spot,
                },
                ...prev.spots,
              ]
              : prev.spots,
          }));

          setSuccessScreen(true);
          resetStates();

          dispatchAnalytcsMsg({
            terrain: 'Concluiu o formulário de indicação de um terreno',
            location: 'Concluiu o formulário de indicação de uma locação',
            spot: 'Concluiu o formulário de indicação de um spot',
          });
        } catch (e: unknown) {
          dispatchAnalytcsMsg({
            terrain: 'Erro ao tentar concluir o formulário de indicação de um terreno',
            location: 'Erro ao tentar concluir o formulário de indicação de uma locação',
            spot: 'Erro ao tentar concluir o formulário de indicação de um spot',
          });
          if (e instanceof AxiosError) {
            toast.error(e.message);
          }
          toast.error('Um erro inesperado aconteceu');
        } finally {
          setLoad(false);
        }
      } else {
        toast.error('Não foi encontrado um identificador para o parceiro');
      }
    },
  });

  useEffect(() => {
    Object.entries(formik.values).forEach(([field, value]: [any, any]) => {
      if (value !== '' && !fieldsChanged.includes(`${field}`)) {
        fieldsChanged.push(`${field}`);
        dispatchAnalytcsMsg({
          terrain: `O campo "${field}" foi alterado no formulário de indicação de um terreno`,
          location: `O campo "${field}" foi alterado no formulário de indicação de uma locação`,
          spot: `O campo "${field}" foi alterado no formulário de indicação de um spot`,
        });
      }
    });
  }, [formik.values]);
  return <Content formik={formik} />;
};

export default Form;
