import styled from 'styled-components';

const MAX_SIZE_MOBILE = 900; // 900px

export const Container = styled.form`
  width: 100%;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  
  p {
    font-weight: 400;
    font-family: Inter;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.palette.grey._560.hex()};
    text-align: start;
    text-justify: distribute;
    text-align-last: left;
    hyphens: auto;
    -webkit-hyphens: auto;
    word-spacing: -0.05em;
  }
`;

export const TextLight = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.grey._560.hex()};
  padding: 8px 0;
  
  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    padding: 16px 0;
  }
`;

export const Title = styled.h4`
  font-family: 'Inter';
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.blue._970.hex()};
  padding-left: 0px;

  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
  }
`;
