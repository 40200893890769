import moment from 'moment';
import * as Yup from 'yup';

export const validation = Yup.object().shape({
  code: Yup.string().required('Campo obrigatório'),
  singleBedQuantity: Yup.number().required('Campo obrigatório').min(0, 'Mínimo 0'),
  doubleBedQuantity: Yup.number().required('Campo obrigatório').min(0, 'Mínimo 0'),
  queenBedQuantity: Yup.number().required('Campo obrigatório').min(0, 'Mínimo 0'),
  kingBedQuantity: Yup.number().required('Campo obrigatório').min(0, 'Mínimo 0'),
  singleSofaBedQuantity: Yup.number().required('Campo obrigatório').min(0, 'Mínimo 0'),
  doubleSofaBedQuantity: Yup.number().required('Campo obrigatório').min(0, 'Mínimo 0'),
  pillowQuantity: Yup.number().required('Campo obrigatório').min(0, 'Mínimo 0'),
  bedroomQuantity: Yup.number().required('Campo obrigatório').min(0, 'Mínimo 0'),
  bathroomQuantity: Yup.number().required('Campo obrigatório').min(0, 'Mínimo 0'),
  lavatoryQuantity: Yup.number().required('Campo obrigatório').min(0, 'Mínimo 0'),
  guestCapacity: Yup.number().required('Campo obrigatório').min(0, 'Mínimo 0'),
  propertyType: Yup.string().required('Campo obrigatório'),
  status: Yup.string().required('Campo obrigatório'),
  activationDate: Yup.string().required('Campo obrigatório'),
  contractStartDate: Yup.string().required('Campo obrigatório'),
  newContractStartDate: Yup.string()
    .test(
      'contractStartDate',
      'A data deve ser maior ou igual ao próximo dia após o início do mês.',
      function validate(value: any) {
        const { parent } = this;
        if (parent.changeContractStartDate) {
          const inputDate = moment(new Date(value), 'YYYY-MM-DD', true);

          const nextDayOfMonth = moment().startOf('month').add(1, 'days');

          return inputDate.isSameOrAfter(nextDayOfMonth);
        }

        return true;
      },
    ),
  contractEndDate: Yup.string().test(
    'contractEndDate', 'Campo obrigatório',
    function validate(value) {
      const { parent } = this;
      if (!value && parent.status === 'Inactive') return false;
      return true;
    },
  ),
  street: Yup.string().required('Campo obrigatório'),
  state: Yup.string().required('Campo obrigatório'),
  neighborhood: Yup.string().required('Campo obrigatório'),
  city: Yup.string().required('Campo obrigatório'),
  country: Yup.string().required('Campo obrigatório'),
  postalCode: Yup.string().min(9, 'Insira um CEP válido').required(),
  host: Yup.number().required('Campo obrigatório'),
  owners: Yup.number().required('Campo obrigatório'),
  hasNewOwner: Yup.boolean().optional(),
  cleaningFee: Yup.string().required('Campo obrigatório'),
  categoryLocationId: Yup.string().min(1, 'Campo obrigatório').required('Campo obrigatório'),
  comissionFee: Yup.number()
    .required('Campo obrigatório')
    .min(0, 'Valor mínimo = 0%')
    .max(100, 'Valor máximo = 100%'),
  hostReservationComissionFee: Yup.number()
    .required('Campo obrigatório')
    .min(0, 'Valor mínimo = 0%')
    .max(100, 'Valor máximo = 100%'),
  extraDayPreparation: Yup.number().required('Campo obrigatório').min(0, 'Mínimo 0'),
});
