import { useState, useEffect } from 'react';
import { useFormik } from 'formik';

import { OwnerContact } from '../../../../services/Owner/types';

import {
  patchOwnerContact,
  postOwnerContact,
} from '../../../../services/Owner/request';

import { phone } from '../../../../utils/InputMask/Number';

import { useToastErrorMessage } from '../../../../utils/Messages';
import { useToast } from '../../../../context/ToastContext';
import { useViewMode } from '../../../../context/ViewMode/ViewMode';
import { useScreenResize } from '../../../../hooks/useSceenResize/useScreenResize';

import ModalConfirmDeleteContact from '../../Modal/ModalConfirmDeleteContact';
import TextField from '../../../TextField/TextField';

import {
  Container,
  Content,
  ContentButton,
  Button,
} from './styles';

interface Props {
  contact: OwnerContact;
  getListOwnerContact: () => Promise<void>;
  sliderRef?: any,
}

const CardPhone = ({ contact, getListOwnerContact, sliderRef }: Props) => {
  const [openModalDeleteContact, setOpenModalDeleteContact] = useState<boolean>(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const { isViewMode } = useViewMode();
  const { isMobile } = useScreenResize();

  const initialValues = {
    name: contact?.contact_name || '',
    phone: contact?.phone_number || '',
    email: contact?.email || '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const contactId = `${contact.id}`;

      try {
        if (contactId.startsWith('new_')) {
          await postOwnerContact({
            contact_name: values.name,
            phone_number: values.phone,
            email: values.email,
          });
          await getListOwnerContact();
          setShowSaveButton(false);
          toast.success('Contato salvo com sucesso!');
          if (sliderRef?.current) {
            sliderRef?.current?.slickGoTo(0);
          }
        } else {
          await patchOwnerContact({
            contact_name: values.name,
            phone_number: values.phone,
            email: values.email,
          },
          contact.id as number);
          await getListOwnerContact();
          setShowSaveButton(false);
          toast.success('Contato atualizado com sucesso!');
          if (sliderRef?.current) {
            sliderRef?.current?.slickGoTo(0);
          }
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      ...initialValues,
      name: contact?.contact_name || '',
      phone: contact?.phone_number || '',
      email: contact?.email || '',
    });
  }, [contact]);

  function handleCancel() {
    formik.resetForm();
    setShowSaveButton(false);
  }

  const ButtonCancel = () => (
    <Button type="button" onClick={handleCancel}>
      <svg width="42" height="41" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="0.5" width="40" height="40" rx="10" stroke="#394760" />
        <path d="M28.281 26.7198C28.3507 26.7895 28.406 26.8722 28.4437 26.9632C28.4814 27.0543 28.5008 27.1519 28.5008 27.2504C28.5008 27.349 28.4814 27.4465 28.4437 27.5376C28.406 27.6286 28.3507 27.7114 28.281 27.781C28.2114 27.8507 28.1286 27.906 28.0376 27.9437C27.9465 27.9814 27.849 28.0008 27.7504 28.0008C27.6519 28.0008 27.5543 27.9814 27.4632 27.9437C27.3722 27.906 27.2895 27.8507 27.2198 27.781L21.0004 21.5607L14.781 27.781C14.6403 27.9218 14.4494 28.0008 14.2504 28.0008C14.0514 28.0008 13.8605 27.9218 13.7198 27.781C13.5791 27.6403 13.5 27.4494 13.5 27.2504C13.5 27.0514 13.5791 26.8605 13.7198 26.7198L19.9401 20.5004L13.7198 14.281C13.5791 14.1403 13.5 13.9494 13.5 13.7504C13.5 13.5514 13.5791 13.3605 13.7198 13.2198C13.8605 13.0791 14.0514 13 14.2504 13C14.4494 13 14.6403 13.0791 14.781 13.2198L21.0004 19.4401L27.2198 13.2198C27.3605 13.0791 27.5514 13 27.7504 13C27.9494 13 28.1403 13.0791 28.281 13.2198C28.4218 13.3605 28.5008 13.5514 28.5008 13.7504C28.5008 13.9494 28.4218 14.1403 28.281 14.281L22.0607 20.5004L28.281 26.7198Z" fill="#394760" />
      </svg>
    </Button>
  );

  const ButtonSave = () => (
    <Button
      type="button"
      data-cy="btn-save-contact"
      onClick={() => formik.handleSubmit()}
      disabled={formik.isSubmitting}
    >
      <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="40" height="40" rx="10" fill="#0D4BD0" />
        <path d="M29.531 15.781L17.531 27.781C17.4614 27.8508 17.3787 27.9061 17.2876 27.9438C17.1966 27.9816 17.099 28.001 17.0004 28.001C16.9019 28.001 16.8043 27.9816 16.7132 27.9438C16.6222 27.9061 16.5394 27.8508 16.4698 27.781L11.2198 22.531C11.0791 22.3903 11 22.1994 11 22.0004C11 21.8014 11.0791 21.6105 11.2198 21.4698C11.3605 21.3291 11.5514 21.25 11.7504 21.25C11.9494 21.25 12.1403 21.3291 12.281 21.4698L17.0004 26.1901L28.4698 14.7198C28.6105 14.5791 28.8014 14.5 29.0004 14.5C29.1994 14.5 29.3903 14.5791 29.531 14.7198C29.6718 14.8605 29.7508 15.0514 29.7508 15.2504C29.7508 15.4494 29.6718 15.6403 29.531 15.781Z" fill="white" />
      </svg>
    </Button>
  );

  const ButtonEdit = () => (
    <Button type="button" onClick={() => setShowSaveButton(true)}>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.3103 7.37915L17.1216 3.18946C16.9823 3.05014 16.8169 2.93962 16.6349 2.86421C16.4529 2.78881 16.2578 2.75 16.0608 2.75C15.8638 2.75 15.6687 2.78881 15.4867 2.86421C15.3047 2.93962 15.1393 3.05014 15 3.18946L3.43969 14.7507C3.2998 14.8895 3.18889 15.0547 3.11341 15.2367C3.03792 15.4188 2.99938 15.614 3.00001 15.811V20.0007C3.00001 20.3985 3.15804 20.7801 3.43935 21.0614C3.72065 21.3427 4.10218 21.5007 4.50001 21.5007H8.6897C8.88675 21.5013 9.08197 21.4628 9.26399 21.3873C9.44602 21.3118 9.61122 21.2009 9.75001 21.061L21.3103 9.50071C21.4496 9.36142 21.5602 9.19604 21.6356 9.01403C21.711 8.83202 21.7498 8.63694 21.7498 8.43993C21.7498 8.24292 21.711 8.04784 21.6356 7.86582C21.5602 7.68381 21.4496 7.51844 21.3103 7.37915ZM8.6897 20.0007H4.50001V15.811L12.75 7.56102L16.9397 11.7507L8.6897 20.0007ZM18 10.6895L13.8103 6.50071L16.0603 4.25071L20.25 8.43946L18 10.6895Z" fill="#001840" />
      </svg>
    </Button>
  );

  const ButtonDelete = () => (
    <Button
      type="button"
      onClick={() => setOpenModalDeleteContact(true)}
    >
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.25 5H16.5V4.25C16.5 3.65326 16.2629 3.08097 15.841 2.65901C15.419 2.23705 14.8467 2 14.25 2H9.75C9.15326 2 8.58097 2.23705 8.15901 2.65901C7.73705 3.08097 7.5 3.65326 7.5 4.25V5H3.75C3.55109 5 3.36032 5.07902 3.21967 5.21967C3.07902 5.36032 3 5.55109 3 5.75C3 5.94891 3.07902 6.13968 3.21967 6.28033C3.36032 6.42098 3.55109 6.5 3.75 6.5H4.5V20C4.5 20.3978 4.65804 20.7794 4.93934 21.0607C5.22064 21.342 5.60218 21.5 6 21.5H18C18.3978 21.5 18.7794 21.342 19.0607 21.0607C19.342 20.7794 19.5 20.3978 19.5 20V6.5H20.25C20.4489 6.5 20.6397 6.42098 20.7803 6.28033C20.921 6.13968 21 5.94891 21 5.75C21 5.55109 20.921 5.36032 20.7803 5.21967C20.6397 5.07902 20.4489 5 20.25 5ZM9 4.25C9 4.05109 9.07902 3.86032 9.21967 3.71967C9.36032 3.57902 9.55109 3.5 9.75 3.5H14.25C14.4489 3.5 14.6397 3.57902 14.7803 3.71967C14.921 3.86032 15 4.05109 15 4.25V5H9V4.25ZM18 20H6V6.5H18V20ZM10.5 10.25V16.25C10.5 16.4489 10.421 16.6397 10.2803 16.7803C10.1397 16.921 9.94891 17 9.75 17C9.55109 17 9.36032 16.921 9.21967 16.7803C9.07902 16.6397 9 16.4489 9 16.25V10.25C9 10.0511 9.07902 9.86032 9.21967 9.71967C9.36032 9.57902 9.55109 9.5 9.75 9.5C9.94891 9.5 10.1397 9.57902 10.2803 9.71967C10.421 9.86032 10.5 10.0511 10.5 10.25ZM15 10.25V16.25C15 16.4489 14.921 16.6397 14.7803 16.7803C14.6397 16.921 14.4489 17 14.25 17C14.0511 17 13.8603 16.921 13.7197 16.7803C13.579 16.6397 13.5 16.4489 13.5 16.25V10.25C13.5 10.0511 13.579 9.86032 13.7197 9.71967C13.8603 9.57902 14.0511 9.5 14.25 9.5C14.4489 9.5 14.6397 9.57902 14.7803 9.71967C14.921 9.86032 15 10.0511 15 10.25Z" fill="#ED6A71" />
      </svg>
    </Button>
  );

  return (
    <div data-cy={`container-phone-${contact.id || 'new'}`}>
      <Container
        data-cy="list-phones"
        onSubmit={formik.handleSubmit}
        isEditing={showSaveButton}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      >
        {!isViewMode && !showSaveButton && isMobile && (
          <ContentButton>
            <ButtonDelete />
            <ButtonEdit />
          </ContentButton>
        )}
        {!isViewMode && showSaveButton && isMobile && (
          <ContentButton>
            <ButtonCancel />
            <ButtonSave />
          </ContentButton>
        )}
        <Content activeBorder={!showSaveButton} isEditing={!showSaveButton}>
          <TextField
            label="Nome do contato"
            id="name"
            placeholder={showSaveButton ? 'Digite aqui...' : 'Clique no lápis para editar...'}
            formik={formik}
            value={formik.values.name}
            dataCy="name-field"
            disabled={!showSaveButton}
          />
        </Content>

        <Content activeBorder={!showSaveButton} isEditing={!showSaveButton}>
          <TextField
            label="E-mail"
            id="email"
            placeholder={showSaveButton ? 'Digite aqui...' : 'Clique no lápis para editar...'}
            formik={formik}
            value={formik.values.email}
            dataCy="email-field"
            disabled={!showSaveButton}
          />
        </Content>

        <Content activeBorder={!showSaveButton} isEditing={!showSaveButton}>
          <TextField
            label="Telefone"
            id="phone"
            placeholder={showSaveButton ? 'Digite aqui...' : 'Clique no lápis para editar...'}
            formik={formik}
            value={phone(formik.values.phone)}
            mask="phone"
            dataCy="phone-field"
            disabled={!showSaveButton}
          />
        </Content>

        {!isViewMode && !isMobile && (
          <Content isEditing={!showSaveButton}>
            {!showSaveButton ? (
              <ContentButton>
                <ButtonDelete />
                <ButtonEdit />
              </ContentButton>
            ) : (
              <ContentButton>
                <ButtonCancel />
                <ButtonSave />
              </ContentButton>
            )}
          </Content>
        )}
      </Container>

      {contact?.id && (
        <ModalConfirmDeleteContact
          idContact={contact.id}
          openModal={openModalDeleteContact}
          setOpenModal={setOpenModalDeleteContact}
          updateListContact={getListOwnerContact}
          sliderRef={sliderRef}
        />
      )}
    </div>
  );
};

CardPhone.defaultProps = {
  sliderRef: undefined,
};

export default CardPhone;
