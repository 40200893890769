import { OwnerInvoiceDetails } from '../../services/Owner/types';
import { cnpj, cpf } from '../../utils/InputMask/Number';

import {
  Container,
  Content,
  TagMain,
  Text,
  TextLight,
} from './styles';

interface Props {
  dataInvoice: OwnerInvoiceDetails;
}

const CardsDataFiscalNote = ({
  dataInvoice,
}: Props) => (
  <Container>
    <Content>
      {dataInvoice.is_default && <TagMain>Principal</TagMain>}
      <div className="account-user-content">
        <Text>Nome</Text>
        <TextLight>{dataInvoice.invoice_entity_name}</TextLight>
      </div>
      {dataInvoice.cpf && dataInvoice.cpf.length > 1 && (
        <div>
          <Text>CPF</Text>
          <TextLight>{cpf(dataInvoice.cpf)}</TextLight>
        </div>
      )}
      {dataInvoice.cnpj && dataInvoice.cnpj.length > 1 && (
        <div>
          <Text>CNPJ</Text>
          <TextLight>{cnpj(dataInvoice.cnpj)}</TextLight>
        </div>
      )}
    </Content>
  </Container>
);

export default CardsDataFiscalNote;
