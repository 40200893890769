import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Container, TitleContainer, TitleContent } from './style';
import { PageTitle } from '../utils/style';

import Accompaniment from './Accompaniment';
import IndicateViews from './IndicateViews';

const Indications = () => {
  const navigate = useNavigate();
  const pathname = useLocation();

  useEffect(() => {
    navigate('/parceiros/painel');
  }, [pathname]);

  return null; // Ocultando paginas de indicações dos parceiros

  return (
    <Container>
      <TitleContainer>
        <TitleContent>
          <PageTitle>Minhas indicações</PageTitle>
        </TitleContent>

        <Accompaniment />
      </TitleContainer>

      <IndicateViews />
    </Container>
  );
};

export default Indications;
