import styled from 'styled-components';

const MAX_SIZE_MOBILE = 900;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fff;
`;

export const ContainerFilter = styled.div`
  display: flex;
  align-items: flex-end;
  height: 97px;
  gap: 16px;
  border-bottom: 1px solid rgba(126, 134, 158, 0.25);
  padding: 16px 0 16px 32px;
`;
export const ContainerStepper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Content = styled.div`
  flex: 1%;
  padding: 16px 32px 16px 32px;

  @media (max-width: 500px) {
    padding: 16px 32px 120px 32px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerCheckbox = styled.div`
  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  padding: 16px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);

  h1 {
    font-family: 'Quicksand', sans-serif;
    font-size: 20px;
  }
`;

export const MultiSelectContent = styled.div<{ widthWindow?: number }>`
  width: 250px;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    width: ${({ widthWindow }) =>
      widthWindow ? `${widthWindow - 24}px` : '350px'};
  }
`;

export const ContainerInitial = styled.div<{ widthWindow?: number }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 24px;

  img {
    width: 131px;
    height: 132px;
  }
`;

export const Label = styled.label`
  font-size: 1rem !important;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.blue._970.hex()} !important;
  text-align: start;
  margin-right: 0.5rem;
  padding-top: 0.7rem;

  @media (max-width: 500px) {
    font-size: 0.9rem !important;
  }
`;
