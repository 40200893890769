import { useRef, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { getOwnerContact } from '../../../services/Owner/request';

import { useScreenResize } from '../../../hooks/useSceenResize/useScreenResize';
import { OwnerContact } from '../../../services/Owner/types';
import { useViewMode } from '../../../context/ViewMode/ViewMode';

import SimpleSlider from '../../Carousel/SimpleSlider';
import ButtonAddPhone from './ButtonAddPhone';
import CardPhone from './CardPhone';

import {
  Container,
  Content,
  ContentMobile,
  TitleContainer,
  Title,
} from './styles';

const Phones = () => {
  const { isMobile } = useScreenResize();
  const { isViewMode, mode } = useViewMode();

  const sliderRef = useRef<any>(null);
  const [listContact, setListContact] = useState<Array<OwnerContact>>([]);

  const getListOwnerContact = async () => {
    const result = await getOwnerContact({ mode });
    setListContact(result);
  };

  function handleAddCardContact() {
    const newIndex = listContact.length > 0 ? listContact.length : 0;
    if (sliderRef?.current) {
      sliderRef?.current?.slickGoTo(newIndex);
    }

    setListContact([...listContact, {
      id: `new_${uuid()}`,
      contact_name: '',
      email: '',
      phone_number: '',
    }]);
  }

  useEffect(() => {
    getListOwnerContact();
  }, []);

  return (
    <Container>
      <TitleContainer>
        <Title>Contatos</Title>
        {!isViewMode && !isMobile && (
          <ButtonAddPhone
            testid="add-contact-phone"
            dataCy="add-contact-phone"
            onClick={() => handleAddCardContact()}
          />
        )}
      </TitleContainer>

      {!isMobile && (
        <Content>
          <section>
            {listContact.map((item) => (
              <CardPhone
                key={item.id}
                contact={item}
                getListOwnerContact={getListOwnerContact}
              />
            ))}
          </section>
        </Content>
      )}

      {isMobile && (
        <ContentMobile>
          <SimpleSlider
            sliderRef={sliderRef}
            settings={{
              dots: false,
              arrows: false,
              infinite: false,
              speed: 500,
              slidesToScroll: 1,
              draggable: listContact.length >= 2,
              slidesToShow: listContact.length >= 2 ? 1.2 : 1,
            }}
          >
            {listContact.map((item) => (
              <CardPhone
                key={item.id}
                contact={item}
                getListOwnerContact={getListOwnerContact}
                sliderRef={sliderRef}
              />
            ))}
          </SimpleSlider>

          {!isViewMode && isMobile && (
            <ButtonAddPhone
              testid="add-contact-phone"
              dataCy="add-contact-phone"
              onClick={() => handleAddCardContact()}
            />
          )}
        </ContentMobile>
      )}
    </Container>
  );
};

export default Phones;
