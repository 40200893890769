import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';

import { useFormik } from 'formik';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  Search,
  CalendarToday,
  MoreVert,
} from '@mui/icons-material';

import { InputAdornment, LinearProgress } from '@mui/material';

import refreshIcon from '../../../assets/icons/financialClose/refresh.svg';
import manualAdjustmentIcon from '../../../assets/icons/financialClose/manualadjustment.svg';
import dataValidationIcon from '../../../assets/icons/financialClose/datavalidation.svg';
import downloadIcon from '../../../assets/icons/financialClose/download.svg';

import {
  Container,
  Content,
  SearchContainer,
  InputContainer,
  FiltersContainer,
  CalendarContainer,
  CalendarContent,
  CalendarModalContainer,
  ContainerActions,
  ButtonActions,
  ModalActions,
  Action,
  Backdrop,
} from './styles';

import TextField from '../../TextField';
import FastDataForwardModal from '../../Calendar/FastDataForwardButton/FastDataForwardModal';

import { useFinancialClose } from '../../../hooks/FinancialCloseHook/useFinancialClose';
import { useFinancialCloseProperty } from '../../../hooks/FinancialCloseHook/useFinancialCloseProperty';
import { useFinancialCloseHost } from '../../../hooks/FinancialCloseHook/useFinancialCloseHost';
import { useFinancialCloseOwner } from '../../../hooks/FinancialCloseHook/useFinancialCloseOwner';
import { PropertyCloseContext } from '../PropertyClose/PropertyClose';
import { HostCloseContext } from '../HostClose/HostClose';
import { OwnerCloseContext } from '../OwnerClose/OwnerClose';
import { useToast } from '../../../context/ToastContext';

import { FinancialCloseParams, Status } from '../../../services/FinancialClose/types';
import { formatDate, translatePath } from '../../../context/FinancialClosePage/FinancialCloseContext/utils';
import { formatRevenuesHost } from '../../../context/FinancialClosePage/FinancialCloseHostContext/utils';
import { formatRevenuesOwner } from '../../../context/FinancialClosePage/FinancialCloseOwnerContext/utils';
import { postFinancialAudit } from '../../../services/FinancialClose/request';
import { getFilesByFileUid } from '../../../services/NFlist/request';
import FinancialUpdateModal from '../FinancialUpdateModal';
import { useUser } from '../../../context/UserContext';

const fecthLoadingStyles = createTheme({
  palette: {
    secondary: {
      main: '#0D4BD0',
    },
  },
});

const Header: React.FC = () => {
  const { userInformation } = useUser();
  const {
    dateSelected,
    handleDateSelected,
    openModalDate,
    handleOpenModalDate,
    openActions,
    handleOpenActions,
    handleOpenModalManualFit,
    actionSelected,
    handleActionSelected,
    handleChangeSearch,
    activeLinearProgress,
    handleActiveLinearProgress,
    openFinancialUpdateModal,
    handleOpenFinancialUpdateModal,
  } = useFinancialClose();

  const {
    financialDataProperty,
    handleUpdateAllCheckedsOfPropertySelected,
  } = useFinancialCloseProperty();

  const { financialDataHost, handleUpdateAllCheckedsOfHostSelected } = useFinancialCloseHost();
  const { financialDataOwner, handleUpdateAllCheckedsOfOwnerSelected } = useFinancialCloseOwner();

  const {
    addFinancialCloseProperty,
    // refetchSelectedProperties,
    validationRevenuesProperty,
  } = useContext(PropertyCloseContext);

  const {
    addFinancialCloseHost,
    // refetchSelectedHosts,
    validationRevenuesHost,
  } = useContext(HostCloseContext);

  const {
    addFinancialCloseOwner,
    // refetchSelectedOwners,
    validationRevenuesOwner,
  } = useContext(OwnerCloseContext);

  const toast = useToast();

  const months: string[] = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
  const [currentMonth, setCurrentMonth] = useState<number>(dateSelected.getMonth());
  const [currentYear, setCurrentYear] = useState<number>(dateSelected.getFullYear());

  const path = useMemo(() => window.location.pathname, [window.location.pathname]);

  const propertiesSelected = financialDataProperty.filter((item) => item.checked);
  const hostsSelected = financialDataHost.filter((item) => item.checked);
  const ownersSelected = financialDataOwner.filter((item) => item.checked);

  useEffect(() => {
    setCurrentMonth(dateSelected.getMonth());
    setCurrentYear(dateSelected.getFullYear());
  }, [dateSelected]);

  const formik = useFormik({
    initialValues: {
      searchTerm: '',
    },
    onSubmit: async (values) => {
      const params: FinancialCloseParams = {
        start_date: formatDate(dateSelected).start_date,
        search: values.searchTerm,
      };

      if (path === '/fechamentoimovel') {
        await addFinancialCloseProperty(params);
        handleUpdateAllCheckedsOfPropertySelected(false);
      } else if (path === '/fechamentoanfitriao') {
        await addFinancialCloseHost(params);
        handleUpdateAllCheckedsOfHostSelected(false);
      } else if (path === '/fechamentoproprietario') {
        await addFinancialCloseOwner(params);
        handleUpdateAllCheckedsOfOwnerSelected(false);
      }

      handleChangeSearch(values.searchTerm.trim());
    },
  });

  const itemIsSelectedInGrid = () => {
    if ((path === '/fechamentoimovel' && propertiesSelected.length === 0)
      || (path === '/fechamentoanfitriao' && hostsSelected.length === 0)
      || (path === '/fechamentoproprietario' && ownersSelected.length === 0)) {
      toast.alert(`Para aplicar esta ação é necessário selecionar no mínimo um ${translatePath[path]}`);
      return false;
    }
    return true;
  };

  // const handleUpdateAction = async () => {
  //   if (!itemIsSelectedInGrid()) return;

  //   handleActiveLinearProgress(true);
  //   handleActionSelected('update');

  //   const params: FinancialCloseParams = {
  //     start_date: formatDate(dateSelected).start_date,
  //   };

  //   if (path === '/fechamentoimovel') {
  //     await refetchSelectedProperties({
  //       ...params,
  //       properties: propertiesSelected.map((item) => `${item.property.id}`),
  //     }, true);
  //   } else if (path === '/fechamentoanfitriao') {
  //     await refetchSelectedHosts({
  //       ...params,
  //       hosts: hostsSelected.map((item) => `${item.host.id}`),
  //     }, true);
  //   } else if (path === '/fechamentoproprietario') {
  //     await refetchSelectedOwners({
  //       ...params,
  //       owners: ownersSelected.map((item) => `${item.owner.id}`),
  //     }, true);
  //   }

  //   handleActiveLinearProgress(false);
  // };

  const handleOpenModalAction = () => {
    if (!itemIsSelectedInGrid()) return;
    handleOpenModalManualFit(true);
    handleActionSelected('adjustments');
  };

  const handleValidationDataAction = async (status: Status) => {
    if (!itemIsSelectedInGrid()) return;

    handleActionSelected(status === 'Closed' ? 'validation' : 'invalidation');
    handleActiveLinearProgress(true);

    const params: FinancialCloseParams = {
      start_date: formatDate(dateSelected).start_date,
    };

    if (path === '/fechamentoimovel') {
      await validationRevenuesProperty({
        ...params,
      }, status);
    } else if (path === '/fechamentoanfitriao') {
      const revenues = formatRevenuesHost(hostsSelected, status);
      await validationRevenuesHost(
        revenues, {
          ...params,
          hosts: hostsSelected.map((item) => `${item.host.id}`),
        }, status,
      );
    } else if (path === '/fechamentoproprietario') {
      const revenues = formatRevenuesOwner(ownersSelected, status);
      await validationRevenuesOwner(
        revenues, {
          ...params,
          owners: ownersSelected.map((item) => `${item.owner.id}`),
        }, status,
      );
    }

    handleActiveLinearProgress(false);
  };

  async function handleExportFinancialAudit() {
    const response = await postFinancialAudit();
    const responseFile = await getFilesByFileUid(response.data.file_uid);
    if (responseFile.status === 'Processing') {
      toast.alert('Oarquivo solicitado ainda não está pronto, tente novamente em algunsinstantes');
    } else {
      window.open(responseFile.url, '_self');
    }
  }

  const handleResetStates = () => {
    handleOpenActions(false);
    handleActionSelected('none');
    handleActiveLinearProgress(false);
  };

  return (
    <>
      <Container>
        <Content
          onChange={formik.handleChange}
          onSubmit={formik.handleSubmit}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          <SearchContainer>
            <InputContainer>
              <TextField
                dataCy="input-search"
                formik={formik}
                id="searchTerm"
                placeholder="Busque por Imóvel, Anfitrião, Proprietário ou Status..."
                value={formik.values.searchTerm}
                startAdornment={(
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
              )}
              />
            </InputContainer>
          </SearchContainer>

          <FiltersContainer>
            <CalendarContainer>
              <p>Período</p>
              <CalendarContent onClick={() => handleOpenModalDate(true)}>
                <CalendarToday />
                <p>{`${months[currentMonth]} ${currentYear}`}</p>
              </CalendarContent>
            </CalendarContainer>

            {openModalDate && (
              <CalendarModalContainer>
                <FastDataForwardModal
                  id="monthModalViewer"
                  defaultValue={dateSelected}
                  setDates={handleDateSelected}
                  onClose={() => handleOpenModalDate(false)}
                  setSelectedMonth={handleDateSelected}
                  showTodayButton={false}
                  defaultView="year"
                />
              </CalendarModalContainer>
            )}

            <ContainerActions>
              <ButtonActions
                data-cy="btn-actions"
                type="button"
                onClick={() => handleOpenActions(!openActions)}
              >
                <MoreVert />
                <p>Ações</p>
              </ButtonActions>
              <ModalActions
                data-cy="modal-actions"
                showActionsOptions={openActions}
              >
                {userInformation?.email === 'd.franzoni@seazone.com.br' && (
                <Action loading={activeLinearProgress && actionSelected === 'update'} onClick={() => handleOpenFinancialUpdateModal(true)}>
                  <img src={refreshIcon} alt="Atualizar dados" />
                  <p data-cy="title-action-update-datas">Atualizar dados</p>
                </Action>
                )}
                {!['/fechamentoimovel', '/fechamentoanfitriao'].includes(path) && (
                  <Action onClick={() => handleOpenModalAction()}>
                    <img src={manualAdjustmentIcon} alt="Ajuste manual" />
                    <p data-cy="title-action-manual-fit">Ajuste manual</p>
                  </Action>
                )}
                {!['/fechamentoanfitriao'].includes(path)
                  && (
                  <>
                    <Action onClick={() => handleValidationDataAction('Closed')}>
                      <img src={dataValidationIcon} alt="Validar dados" />
                      <p data-cy="title-action-validate-datas">Validar dados</p>
                    </Action>
                    <Action onClick={() => handleValidationDataAction('Open')}>
                      <img src={dataValidationIcon} alt="Desvalidar dados" />
                      <p data-cy="title-action-desvalidate-datas">Desvalidar dados</p>
                    </Action>
                  </>
                  )}

                {path === '/fechamentoimovel' && (
                  <>
                    <a
                      style={{ width: '100%', textDecoration: 'none' }}
                      href="https://metabase.sapron.com.br/question/180-export-de-reservas-para-conciliacao"
                      target="_blank"
                    >
                      <Action>
                        <img src={downloadIcon} alt="Exportar reservas" />
                        <p data-cy="title-action-export-reservation">Exportar reservas</p>
                      </Action>
                    </a>
                    <Action className="styled" onClick={() => handleExportFinancialAudit()}>
                      <img src={downloadIcon} alt="Exportar CSV auditoria de meses fechados" />
                      <p data-cy="title-action-export-audit">Exportar auditoria de meses fechados</p>
                    </Action>
                  </>
                )}
              </ModalActions>
            </ContainerActions>
          </FiltersContainer>
        </Content>
      </Container>

      {activeLinearProgress && (
      <ThemeProvider theme={fecthLoadingStyles}>
        <LinearProgress color="secondary" />
      </ThemeProvider>
      )}

      { openActions && (
        <Backdrop onClick={() => handleResetStates()} />
      )}
      { openFinancialUpdateModal && (
        <FinancialUpdateModal
          handleCloseModal={() => handleOpenFinancialUpdateModal(false)}
          isModalOpen={openFinancialUpdateModal}
        />
      )}
    </>
  );
};

export default Header;
