import styled from 'styled-components';

const MAX_SIZE_MOBILE = 900; // 900px

export const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  background: ${({ theme }) => theme.palette.white.main.hex()};
  padding: 16px;
  margin-bottom: 24px;
`;

export const ContentMobile = styled.div`
 .slick-track {
    display: flex !important;
  }
  .slick-slide {
    margin: 0 8px !important;
  }
`;

export const Content = styled.div`
  padding: 0 16px 0 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-content: flex-start;
  align-items: flex-start;

  .slick-prev {
    left: -10px !important;
    z-index: 100 !important;
  };

  .slick-next {
    z-index: 100 !important;
  };

  .slick-prev:before,
  .slick-next:before {
    color: ${({ theme }) => theme.palette.blue._970.hex()} !important;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;  
`;

export const Title = styled.h4`
  font-family: 'Inter';
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: ${({ theme }) => theme.palette.blue._970.hex()};
  padding-left: 0px;

  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    padding-left: 8px;
    font-weight: 500;
    font-size: 20px;
  }
`;
