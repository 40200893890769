import { OwnerInvoiceDetails } from '../../../services/Owner/types';

import CardsDataFiscalNote from '../../CardDataFiscalNote';

import {
  Container,
  Title,
  TextLight,
} from './styles';

interface IInvoices {
  listOwnerInvoice: Array<OwnerInvoiceDetails>;
}

const Invoices = ({
  listOwnerInvoice = [],
}: IInvoices) => (
  <Container>
    <Title>Dados para nota fiscal</Title>
    <TextLight>Dados que vão para sua nota fiscal lançada mensalmente.</TextLight>
    <section>
      {listOwnerInvoice?.map((item) => (
        <CardsDataFiscalNote
          key={item.id}
          dataInvoice={item}
        />
      ))}
    </section>
  </Container>
);

export default Invoices;
