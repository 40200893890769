import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #d6ddeb;
  border-radius: 10px;
  padding: 9px 13px;
  min-width: 1349px;
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 90vh;
  padding: 20px;
`;

export const Content = styled.div`
  width: 100%;
  height: 90%;
  overflow-y: scroll;
`;
