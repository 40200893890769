import React from 'react';

import {
  AnimateSharedLayout,
} from 'framer-motion';

import {
  Container,
  ListContent,
} from './style';

import Item from './Item';
import { IFiles } from './types';
import { item, list } from './utils';

const CardsList = ({
  isEditing,
  title,
  required = false,
  category,
  maintenanceFiles,
  statementFiles,
  uploadMaintenanceFile,
  uploadStatementFile,
  setMaintenanceFiles,
  setStatementFiles,
  setUploadMaintenanceFile,
  setUploadStatementFile,
  setSendingFileArray,
  allInformationExpenseFiles,
  setAllInformationExpenseFiles,
}: IFiles) => (
  <Container
    variants={list}
    initial="hidden"
    animate="visible"
  >
    <AnimateSharedLayout>
      <ListContent layout variants={item}>
        <Item
          isEditing={isEditing}
          title={title}
          required={required}
          category={category}
          maintenanceFiles={maintenanceFiles}
          setMaintenanceFiles={setMaintenanceFiles}
          statementFiles={statementFiles}
          setStatementFiles={setStatementFiles}
          uploadStatementFile={uploadStatementFile}
          setUploadStatementFile={setUploadStatementFile}
          uploadMaintenanceFile={uploadMaintenanceFile}
          setUploadMaintenanceFile={setUploadMaintenanceFile}
          setSendingFileArray={setSendingFileArray}
          allInformationExpenseFiles={allInformationExpenseFiles}
          setAllInformationExpenseFiles={setAllInformationExpenseFiles}
        />
      </ListContent>
    </AnimateSharedLayout>
  </Container>
);

export default CardsList;
