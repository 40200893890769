/* eslint-disable max-len */
import axios from 'axios';
import request from '../../request';

import {
  FinancialCloseParams,
  FinancialCloseHostResponse,
  ManualFitParams,
  ManualFitProps,
  RevenuesHostProps,
  ReservationHostResponseProps,
  FinancialCloseHostDetailsParams,
  FranchseFeeParams,
  FranchseFeeProps,
  ProperPayHostMonthlyResponse,
  ProperPayHostMonthlyDataReduced,
  ProperPayHostMonthlyResults,
  ProperPayHostFranchiseFeeResponse,
} from './types';

import {
  formatFinancialDataHost,
  formatFinancialCloseHostDetails,
  formatProperPayDataHost,
  formatProperPayCloseHostDetails,
} from './utils';

import { formatDate } from '../../../context/FinancialClosePage/FinancialCloseContext/utils';
import { formatedDateOfRequest, numberToCurrency } from '../../../utils/Formatter';
import { GridHostDetailsProps } from '../../../context/FinancialClosePage/FinancialCloseHostContext/types';

const formatProperPayHost = async (params: any): Promise<any> => {
  try {
    const date = params?.start_date || formatDate(new Date()).start_date;
    const [year, month] = date.split('-');
    const response = await request.get<ProperPayHostMonthlyResponse>('proper_pay/host/monthly/', { params });

    const responseResults = response?.data?.results || [];
    const sumValuesByHostId = (results: ProperPayHostMonthlyResults[])
    : ProperPayHostMonthlyResults[] => {
      const hostIdMap: Record<number, ProperPayHostMonthlyResults> = {};

      results.forEach((result) => {
        const hostId = result.host.id;

        if (!hostIdMap[hostId]) {
          hostIdMap[hostId] = { ...result };
        } else {
          hostIdMap[hostId].revenue += result.revenue;
          hostIdMap[hostId].cleaning_fee += result.cleaning_fee;
          hostIdMap[hostId].onboarding_expenses += result.onboarding_expenses;
          hostIdMap[hostId].manual_fit += result.manual_fit;
          hostIdMap[hostId].commission += result.commission;
          hostIdMap[hostId].refund_expenses += result.refund_expenses;
          hostIdMap[hostId].debited_expenses += result.debited_expenses;
          hostIdMap[hostId].transfer += result.transfer;
        }
      });

      return Object.values(hostIdMap);
    };

    const sumResults = sumValuesByHostId(responseResults);

    const responseReduced: ProperPayHostMonthlyDataReduced = sumResults.reduce((acc, item) => {
      acc[item.host.id] = { ...item };
      return acc;
    }, {} as ProperPayHostMonthlyDataReduced);

    const hostIds = params?.hosts ? params?.hosts?.join(',') : Object.keys(responseReduced).join(',');

    const responseFranchise: ProperPayHostFranchiseFeeResponse = !hostIds ? {
      month_ref: `${year}-${month}`,
      franchise_fee: [],
    } : (await request.get('/proper_pay/host/franchise-fee/', {
      params: {
        month_ref: `${year}-${month}`,
        host_ids: hostIds,
      },
    })).data;

    return {
      year, month, sumResults, responseFranchise, responseReduced,
    };
  } catch (error) {
    if (axios.isCancel(error)) {
      return 'Request Canceled!';
    }
    return error;
  }
};

const formatFinancialHost = async (params?: FinancialCloseParams, abortController?: any): Promise<FinancialCloseHostResponse> => {
  try {
    let allParams: FinancialCloseParams = {
      start_date: params?.start_date || formatDate(new Date()).start_date,
    };

    if (params?.end_date) allParams = { ...allParams, end_date: params.end_date };
    if (params?.search) allParams = { ...allParams, search: params.search };

    let paramHosts: string = '';
    const length = params?.hosts?.length || 0;

    if (params?.hosts) {
      params.hosts.forEach((id, index) => {
        paramHosts += index !== (length - 1) ? `hosts[]=${id}&` : `hosts[]=${id}`;
      });
    }

    const url = params?.hosts ? `/admin_financial_host/?${paramHosts}` : '/admin_financial_host/';

    const payload = abortController ? {
      params: {
        ...allParams,
      },
      signal: abortController.signal,
    } : {
      params: {
        ...allParams,
      },
    };

    const { data } = await request.get<FinancialCloseHostResponse>(url, payload);

    return data;
  } catch (error) {
    return {} as FinancialCloseHostResponse;
  }
};

export const getFinancialCloseHost = async (params?:
FinancialCloseParams, abortController?: any) => {
  try {
    const date = params?.start_date || formatDate(new Date()).start_date;
    const [year, month] = date.split('-');

    if (Number(year) < 2024) {
      const data = await formatFinancialHost(params, abortController);
      const { responseReduced, responseFranchise } = await formatProperPayHost(params);

      const financialData = formatFinancialDataHost(
        data, year, month, responseReduced, responseFranchise,
      );
      return financialData;
    }

    const { sumResults, responseFranchise } = await formatProperPayHost({
      month_ref: `${year}-${month}`,
      page_size: 99999999,
      search: params?.search,
    });

    const financialData = formatProperPayDataHost(
      sumResults, responseFranchise,
    );

    return financialData;
  } catch (error) {
    if (axios.isCancel(error)) {
      return 'Request Canceled!';
    }
    return error;
  }
};

export const getFinancialCloseHostDetails = async (params: FinancialCloseHostDetailsParams) => {
  const { revenueIds, hostId, startDate } = params;

  try {
    let allParams: string = '';
    const [year, month] = startDate.split('-');

    if (Number(year) < 2024) {
      if (revenueIds) {
        (revenueIds || []).forEach((id, index) => {
          allParams += index !== (revenueIds.length - 1) ? `ids[]=${id}&` : `ids[]=${id}`;
        });
      }

      const url = `/admin_financial_host/details?${allParams}`;

      const { data } = await request.get<ReservationHostResponseProps[]>(url, {
        params: allParams,
      });

      const response = await request.get<ProperPayHostMonthlyResponse>('proper_pay/host/monthly/', {
        params: {
          month_ref: `${year}-${month}`,
          page_size: 99999999,
          search: hostId,
        },
      });

      const details = formatFinancialCloseHostDetails(data, response?.data?.results || []);

      return details as GridHostDetailsProps;
    }

    const response = await request.get<ProperPayHostMonthlyResponse>('proper_pay/host/monthly/', {
      params: {
        month_ref: `${year}-${month}`,
        page_size: 99999999,
        search: hostId,
      },
    });

    const details = formatProperPayCloseHostDetails(response?.data?.results || []);

    return details as GridHostDetailsProps;
  } catch (error) {
    return {} as GridHostDetailsProps;
  }
};

export const patchRevenuesHost = async (revenues: RevenuesHostProps) => {
  await request({
    method: 'PATCH',
    url: '/admin_financial_host/',
    data: { ...revenues },
  });
};

export const getManualFitHost = async (params?: ManualFitParams) => {
  let allParams: ManualFitParams = {
    start_date: params?.start_date || formatDate(new Date()).start_date,
  };

  if (params?.end_date) allParams = { ...allParams, end_date: params.end_date };
  if (params?.is_adding) allParams = { ...allParams, is_adding: params.is_adding };
  if (params?.host_id) allParams = { ...allParams, host_id: params.host_id };

  const { data } = await request.get<ManualFitProps[]>('/host_manual_fit/', {
    params: allParams,
  });

  const dataFormatted = data.map((item) => ({
    ...item,
    value: Number(item.value),
  }));

  return dataFormatted;
};

export const deleteManualFitHost = async (id: number) => request.delete(`/host_manual_fit/${id}/`);

export const postManualFitHost = async (data: ManualFitProps) => {
  const body = {
    date_ref: formatedDateOfRequest(data.date_ref || '', 'DD/MM/YYYY'),
    value: data.value,
    is_adding: data.is_adding,
    host: data.host,
    description: data.description,
    property: data.property,
    is_related_property: data.is_related_property,
  };

  return request.post<ManualFitProps>('/host_manual_fit/', {
    ...body,
  });
};

export const patchManualFitHost = async (data: ManualFitProps) => {
  const body = {
    date_ref: formatedDateOfRequest(data.date_ref || '', 'DD/MM/YYYY'),
    value: data.value,
    is_adding: data.is_adding,
    host: data.host,
    description: data.description,
  };

  return request.patch<ManualFitProps>(`/host_manual_fit/${data.id}/`, {
    ...body,
  });
};

export const getHostFranchiseFee = async (params?: FranchseFeeParams) => {
  let allParams: FranchseFeeParams = {};

  if (params?.start_date) allParams = { ...allParams, start_date: params.start_date };
  if (params?.end_date) allParams = { ...allParams, end_date: params.end_date };
  if (params?.host_id) allParams = { ...allParams, host_id: params.host_id };

  const { data } = await request.get<FranchseFeeProps[]>('/host_franchise_fee/', {
    params: allParams,
  });

  const dataFormatted = data.sort((a, b) => new Date(`${b.created_at}`).getTime()
  - new Date(`${a.created_at}`).getTime())?.[0] || {} as FranchseFeeProps;

  return (Object.keys(dataFormatted).length !== 0 ? {
    ...dataFormatted,
    franchise_fee_amount: numberToCurrency(dataFormatted.franchise_fee_amount as number),
    rebate_percentage: `${dataFormatted.rebate_percentage}%`,
    final_date_franchise_fee: `${dataFormatted.final_date_franchise_fee}`.split('-').reverse().join('/'),
  } : {}) as FranchseFeeProps;
};

export const postHostFranchiseFee = async (data: FranchseFeeProps) => {
  const body = {
    franchise_fee_amount: data.franchise_fee_amount,
    rebate_percentage: data.rebate_percentage,
    have_grace_period: data.have_grace_period,
    final_date_franchise_fee: data.final_date_franchise_fee,
    month_ref: data.month_ref,
    host: data.host,
  };

  return request.post<FranchseFeeProps>('/host_franchise_fee/', {
    ...body,
  });
};
