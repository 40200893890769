/* eslint-disable no-restricted-globals */
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import moment from 'moment';
import { PostPropertyStatusObject, Status } from '../../../services/InsertData/types';
import FormButton from '../../FormButton';
import FormChangePropertyStatus from './FormChangePropertyStatus/FormChangePropertyStatus';
import {
  ButtonsContainer, Container, Divider, Form, Title,
} from './styles';
import { useToast } from '../../../context/ToastContext';
import { useToastErrorMessage } from '../../../utils/Messages';
import { postChangePropertyStatus } from '../../../services/InsertData/request';
import usePropertyManager from '../../../hooks/usePropertyManager';

type InsertChangePropertyStatusFormikObject = {
  status: Status;
  property: number;
  exchangeDate: Date | null;
};

const validation = Yup.object().shape({
  status: Yup.string().required('Campo Obrigatório'),
  property: Yup.number().required('Campo Obrigatório').min(0, 'Selecione uma propriedade'),
});

const InsertChangePropertyStatus = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const toastErrorRequest = useToastErrorMessage();
  const {
    data: properties,
  } = usePropertyManager();

  const formik = useFormik<InsertChangePropertyStatusFormikObject>({
    initialValues: {
      status: '' as Status,
      property: -1,
      exchangeDate: null,
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        const changePropertyStatus: PostPropertyStatusObject = {
          property: Number(values.property),
          status: values.status,
          exchange_date: values.exchangeDate === null ? undefined : moment(values.exchangeDate).format('YYYY-MM-DD'),
        };

        if (values.status === 'Active') {
          const contractStartDate = properties?.find(
            (item) => item.id === Number(values.property))?.contract_start_date;

          const data1 = moment(changePropertyStatus.exchange_date, 'YYYY-MM-DD');
          const data2 = moment(contractStartDate, 'YYYY-MM-DD');

          if (data1.isBefore(data2)) {
            toast.error('A data de ativação do imóvel não pode ser menor que a data de início do contrato!');
            return;
          }
        }

        await postChangePropertyStatus(changePropertyStatus);
        toast.success('Status da propriedade alterado com sucesso!');

        navigate('/inserirdados');
        formik.resetForm();
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  return (
    <Container>
      <div className="header">
        <div>
          <Title>Mudança de status do imóvel</Title>
        </div>
      </div>
      <div className="divider">
        <Divider />
      </div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="formContainer">
          <FormChangePropertyStatus formik={formik} />
        </div>
        <ButtonsContainer>
          <div>
            <FormButton
              type="button"
              variant="outlined"
              onClick={() => { navigate('/inserirdados'); }}
            >
              Cancelar
            </FormButton>
            <FormButton type="submit" disable={formik.values.exchangeDate === null}>Salvar</FormButton>
          </div>
        </ButtonsContainer>
      </Form>
    </Container>
  );
};
export default InsertChangePropertyStatus;
