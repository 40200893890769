import React from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  margin: 6px 16px 6px 0;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CheckboxInput = styled.input`
  width: 16px;
  height: 16px;
  min-width: 16px;
  border-radius: 4px;
  appearance: none;
  background-color: #fff;
  border: 1px solid black;
  position: relative;
  &:checked {
    background-color: black;
    border: 1px solid black;
  }

  &:checked::after {
    content: '✔';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 12px;
  }
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 400;
  font-family: 'Quicksand', sans-serif;
  color: #000;
`;

const InputCheckBox = ({ checked, labelName, onChange }: any) => (
  <CheckboxContainer>
    <CheckboxInput type="checkbox" checked={checked} onChange={onChange} />
    <Label title={labelName}>{labelName}</Label>
  </CheckboxContainer>
);

export default InputCheckBox;
